import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../application/application";
import { QuoteForm } from "../quote-form";
import { trackUserInteraction } from "../quote-form.utils";
import { useGetRateEstimateTermsState } from "../../redux/rate-estimate-terms/rate-estimate-terms.hooks";
import style from "./terms.scss";
import { convertTermsToJSX } from "./terms.utils";

interface Props {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm | Application>["formData"];
}
export const QuoteFormTermsComponent: React.FunctionComponent<Props> = ({
  fieldAdapter,
  formData,
}) => {
  const termsState = useGetRateEstimateTermsState().terms;
  const [showCommunicationConsent, setShowCommunicationConsent] =
    React.useState(false);

  /* istanbul ignore next */
  const toggleCommunicationConsent = React.useCallback(
    (e) => {
      e.preventDefault();
      setShowCommunicationConsent(!showCommunicationConsent);
    },
    [showCommunicationConsent]
  );

  const terms = convertTermsToJSX(termsState);

  React.useEffect(() => {
    if (
      formData.termsAndConditionsAgreement &&
      formData.termsAndConditionsAgreement.length > 0
    ) {
      trackUserInteraction({ fieldName: "termsAndConditions" });
    }
  }, [formData.termsAndConditionsAgreement]);

  React.useEffect(() => {
    const communicationConsentLink = document.getElementById(
      "communicationConsentLink"
    );
    /* istanbul ignore next */
    communicationConsentLink?.addEventListener(
      "click",
      toggleCommunicationConsent
    );
  }, [toggleCommunicationConsent, termsState]);

  return (
    <div className="terms">
      <style jsx>{style}</style>
      {terms.termsSection}
      {showCommunicationConsent && terms.communicationConsentSection}
      <Field
        type="checkbox"
        label=""
        onMouseUp={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
          trackUserInteraction({ fieldName: "termsAndConditions", event: e })
        }
        onTouchEnd={(e: React.TouchEvent<HTMLInputElement>) =>
          trackUserInteraction({ fieldName: "termsAndConditions", event: e })
        }
        options={[
          "I have read, understood and agree to the terms and conditions above.",
        ]}
        {...fieldAdapter({
          name: "termsAndConditionsAgreement",
          required: "You must agree to the terms and conditions to proceed.",
        })}
      ></Field>
    </div>
  );
};
