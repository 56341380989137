import { isBrowser, isNotEmpty } from "@wisr/common";
import React from "react";

import { GatsbyPageProps } from "../types/gatsby";
import { MultiPageQuoteForm } from "./multi-page-quote-form/multi-page-quote-form";
import { QuoteFormPageQuery } from "./quote-form";
import { useGetRateEstimatePrefillState } from "../redux/rate-estimate-prefill/rate-estimate-prefill.hooks";
import { mapToQuoteForm } from "../redux/rate-estimate-prefill/rate-estimate-prefill.utils";
import { useDispatch } from "react-redux";
import { fetchRateEstimatePrefill } from "../redux/rate-estimate-prefill/rate-estimate-prefill.thunk";
import { FetchStatus } from "../shared/redux.consts";
import { useGetCustomerLoanParametersState } from "../redux/customer-loan-parameters/customer-loan-parameters.hooks";
import { fetchCustomerLoanParameters } from "../redux/customer-loan-parameters/customer-loan-parameters.thunk";
import { redirect } from "../shared/browser.utils";
import { QUOTE_FORM } from "../shared/url.consts";
import { RATE_ESTIMATE_BID } from "../shared/bid.consts";
import { useGetUser } from "../user/user.hooks";

type Props = GatsbyPageProps<void, QuoteFormPageQuery>;

export const QuoteFormComponent = ({ data, location }: Props) => {
  const { commonValues } = data ? data : { commonValues: null };
  const dispatch = useDispatch();
  const user = useGetUser();
  const rateEstimatePrefillState = useGetRateEstimatePrefillState();
  const customerLoanParametersState = useGetCustomerLoanParametersState();
  const quoteFormInitial = mapToQuoteForm(
    rateEstimatePrefillState.rateEstimatePrefill,
    commonValues
  );

  React.useEffect(() => {
    if (rateEstimatePrefillState.status === FetchStatus.IDLE) {
      dispatch(fetchRateEstimatePrefill());
    }
  }, [dispatch, rateEstimatePrefillState.status]);

  React.useEffect(() => {
    if (customerLoanParametersState.status === FetchStatus.IDLE) {
      dispatch(fetchCustomerLoanParameters());
    }
  }, [dispatch, customerLoanParametersState.status]);

  React.useEffect(() => {
    if (
      rateEstimatePrefillState.status === FetchStatus.FAILED &&
      rateEstimatePrefillState.error &&
      isNotEmpty(user)
    ) {
      redirect(
        `${QUOTE_FORM}?wcToken=${user.access_token}&Product=Dashboard&bid=${RATE_ESTIMATE_BID}`
      );
    }
  }, [rateEstimatePrefillState.error, rateEstimatePrefillState.status, user]);

  if (
    !isBrowser() ||
    !quoteFormInitial ||
    !customerLoanParametersState.loanParameters ||
    !commonValues
  ) {
    return null;
  }

  return (
    <MultiPageQuoteForm
      commonValues={commonValues}
      quoteFormInitial={quoteFormInitial}
      loanParametersResponse={customerLoanParametersState.loanParameters}
      location={location}
    />
  );
};
