import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import React, { FormEventHandler } from "react";

import { QuoteForm } from "../../../quote-form";
import style from "../../multi-page-quote-form.scss";
import reviewEstimateStyle from "./review-estimate.scss";
import { ReviewAccordion } from "./review-accordion/review-accordion";
import { QuoteFormTermsComponent } from "../../../terms/terms.component";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  formState: UseFormTools<QuoteForm>["formState"];
  onSubmit: FormEventHandler<HTMLFormElement>;
  submitting: boolean;
}

export const ReviewEstimate: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  onSubmit,
  submitting,
}) => {
  const isDisabled = !formData.termsAndConditionsAgreement.length || submitting;

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.REVIEW);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <style jsx>{reviewEstimateStyle}</style>
      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">Review your details</h2>
          <p>Please check all your details are correct.</p>
        </div>

        <div className="input-wrapper">
          <ReviewAccordion formData={formData} />
        </div>

        <h3 className="terms-heading">Terms, Privacy & Consent</h3>

        <QuoteFormTermsComponent
          fieldAdapter={fieldAdapter}
          formData={formData}
        />

        <form className="button-wrapper" noValidate onSubmit={onSubmit}>
          <Button theme="navy" disabled={isDisabled} fullWidth={true}>
            <button type="submit" disabled={isDisabled}>
              Get personalised rate
            </button>
          </Button>
        </form>

        <p className="button-text">
          Don&apos;t worry, this won&apos;t impact
          <br />
          your credit score
        </p>
      </div>
    </div>
  );
};
