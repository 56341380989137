import React from "react";
import { Purpose } from "../../../../types/loan-parameters";
import { BrushIcon } from "../../../assets/svg/brush/brush-icon";
import { CarIcon } from "../../../assets/svg/car/car-icon";
import { MoneyIcon } from "../../../assets/svg/money/money-icon";
import { CaravanIcon } from "../../../assets/svg/caravan/caravan-icon";
import { GapFinanceIcon } from "../../../assets/svg/gap-finance/gap-finance-icon";
import { MotorbikeIcon } from "../../../assets/svg/motorbike/motorbike-icon";

export const radioPurposeCodes = ["car", "consolidation", "home-improvements"];
export const backupRadioPurposeCodes = ["caravan", "gap-finance", "motor-bike"];

export const radioIcon = (code: string) => {
  switch (code) {
    case "car":
      return <CarIcon />;
    case "consolidation":
      return <MoneyIcon />;
    case "home-improvements":
      return <BrushIcon />;
    case "caravan":
      return <CaravanIcon />;
    case "gap-finance":
      return <GapFinanceIcon />;
    case "motor-bike":
      return <MotorbikeIcon />;
    default:
      return <></>;
  }
};

export const useRadioPurposes = (
  purposes: Purpose[]
): RadioLoanPurposeItem[] => {
  const radioPurposes = purposes.filter((purpose) =>
    radioPurposeCodes.includes(purpose.key)
  );

  radioPurposes.sort((a, b) => {
    return radioPurposeCodes.indexOf(a.key) - radioPurposeCodes.indexOf(b.key);
  });

  if (radioPurposes.length < 3) {
    const firstMatch = backupRadioPurposeCodes
      .map((code) => purposes.find((purpose) => purpose.key === code))
      .find((purpose) => purpose !== undefined);
    firstMatch && radioPurposes.push(firstMatch);
  }

  return radioPurposes.map((purpose) => {
    const purposeDescription =
      purpose.key === "car" ? "Car (New/Used)" : purpose.description;

    return {
      label: purposeDescription,
      value: purpose.key,
      icon: radioIcon(purpose.key),
    };
  });
};

export const useSelectPurposes = (
  purposes: Purpose[],
  radioPurposes: RadioLoanPurposeItem[]
): LoanPurposeItem[] => {
  const selectPurposes = purposes.filter(
    (purpose) =>
      !radioPurposes.find((radioPurpose) => radioPurpose.value === purpose.key)
  );

  selectPurposes.sort((a, b) => a.description.localeCompare(b.description));
  return selectPurposes.map((purpose) => ({
    label: purpose.description,
    value: purpose.key,
  }));
};

interface LoanPurposeItem {
  label: string;
  value: string;
}

interface RadioLoanPurposeItem extends LoanPurposeItem {
  icon: JSX.Element;
}
