/* istanbul ignore file - graphql/page query cant be reliably tested */

import { graphql } from "gatsby";

import { QuoteFormComponent } from "../../quote/quote-form.component";

export default QuoteFormComponent;

export const query = graphql`
  query QuoteFormPage {
    commonValues {
      loanAmount {
        min
        max
        securedMin
        securedMax
      }
      flexibleLoanAmount {
        minimumUnsecured
        minimumSecured
        maximumUnsecured
        maximumSecured
      }
      purposes {
        code
        description
        secureable
        validFor7YearTerm
      }
      flexibleLoanPurposes {
        rateEstimate {
          limits {
            secure {
              min
              max
            }
            unsecure {
              min
              max
            }
          }
          securePurposes
          securedAssetMaxAge
        }
        loanPurpose {
          key
          description
          isSecurable
          imageUrl
        }
        monthlyAccountFee
      }
      residency {
        code
        description
      }
      states {
        code
        description
      }
      employment {
        code
        description
      }
      title {
        code
        description
      }
      streetTypes {
        code
        description
        common
      }
      assetAgeBands {
        label
        value
        assumedAssetAge
        rateType
      }
      minEligibleIncome
    }
  }
`;
