import React from "react";

export const BrushIcon = () => {
  return (
    <svg
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.5488"
        y="18.7317"
        width="3.90244"
        height="11.7073"
        className="icon-fill"
      />
      <g clipPath="url(#clip0_16_11810)">
        <path
          d="M27.8351 3.32299C27.8351 3.32299 27.8351 3.32299 27.8307 3.32299V0H4.28861V3.17195H0.0605469V14.4442H13.5497V17.7198H12.9611C12.3033 17.7198 11.7667 18.2506 11.7667 18.9109V30.3644C11.7667 31.2664 12.5024 32 13.4069 32H15.7827C16.6872 32 17.4229 31.2664 17.4229 30.3644V18.9109C17.4229 18.2549 16.8906 17.7198 16.2284 17.7198H15.5793V12.4159H2.09452V5.20027H4.28861V8.31612C4.28861 8.588 4.50932 8.80378 4.77763 8.80378H27.3374C27.61 8.80378 27.8264 8.58368 27.8264 8.31612V5.52394C27.8264 5.52394 27.8264 5.52394 27.8307 5.52394C28.4409 5.52394 28.9343 5.03196 28.9343 4.42347C28.9343 3.81497 28.4409 3.32299 27.8307 3.32299H27.8351ZM14.1901 19.4417H15.2288C15.4841 19.4417 15.6918 19.6488 15.6918 19.9034V29.6394C15.6918 29.9889 15.4062 30.2738 15.0557 30.2738H14.1382C13.7877 30.2738 13.5021 29.9889 13.5021 29.6394V19.9034C13.5021 19.6488 13.7098 19.4417 13.9651 19.4417H14.1945H14.1901Z"
          fill="#15384F"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_11810">
          <rect
            width="28.878"
            height="32"
            fill="white"
            transform="translate(0.0605469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
