import React from "react";

export const MoneyIcon = () => {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_12224)">
        <rect
          x="0.0996094"
          y="0.800049"
          width="24.8"
          height="28.8"
          rx="2"
          className="icon-fill"
        />
        <path
          d="M23.1307 0H1.8685C0.895255 0 0.0996094 0.798752 0.0996094 1.7758V3.55159C0.0996094 2.56742 0.895255 1.7758 1.8685 1.7758H23.1236C24.104 1.7758 24.8925 2.57455 24.8925 3.55159V1.7758C24.8925 0.79162 24.0969 0 23.1236 0H23.1307Z"
          fill="#15384F"
        />
        <path
          d="M13.4199 25.7384C13.8035 25.6029 14.1516 25.3818 14.45 25.0894C14.9686 24.5688 15.2599 23.8699 15.2599 23.1353C15.2599 22.4007 14.9757 21.7018 14.45 21.1812C14.1516 20.8817 13.8035 20.6677 13.4199 20.5322V25.7455V25.7384Z"
          fill="#15384F"
        />
        <path
          d="M9.74707 15.754C9.74707 16.4957 10.0312 17.1875 10.5569 17.7081C10.8553 18.0076 11.2034 18.2216 11.587 18.3571V13.1438C11.2034 13.2793 10.8553 13.5004 10.5569 13.7928C10.0383 14.3134 9.74707 15.0123 9.74707 15.7469V15.754Z"
          fill="#15384F"
        />
        <path
          d="M24.8996 3.55164H16.0409C16.0409 5.51286 14.4568 7.10323 12.4961 7.10323C10.5354 7.10323 8.95827 5.51286 8.95117 3.55164H0.0996094V32H24.8996V3.55164ZM14.2863 18.8848C14.8333 19.1202 15.3234 19.4482 15.7426 19.869C16.1617 20.2898 16.4956 20.7819 16.7229 21.331C16.9645 21.9015 17.0852 22.5006 17.0852 23.1282C17.0852 23.7558 16.9645 24.3548 16.7229 24.9254C16.4885 25.4745 16.1617 25.9666 15.7426 26.3874C15.3234 26.8082 14.8333 27.1433 14.2863 27.3716C14.0021 27.4928 13.7108 27.5784 13.4196 27.6426V29.5681H11.5796V27.6426C11.2813 27.5855 10.99 27.4928 10.713 27.3716C10.166 27.1362 9.67578 26.8082 9.25664 26.3874C8.83751 25.9666 8.50362 25.4745 8.27629 24.9254C8.03476 24.3548 7.91399 23.7558 7.91399 23.1282H9.75392C9.75392 23.8699 10.0381 24.5617 10.5638 25.0823C10.8621 25.3818 11.2102 25.5958 11.5939 25.7313V20.2684C11.2955 20.2113 11.0042 20.1186 10.7272 19.9974C10.1802 19.762 9.68998 19.434 9.27085 19.0132C8.85171 18.5924 8.51783 18.1003 8.2905 17.5512C8.04896 16.9807 7.9282 16.3816 7.9282 15.754C7.9282 15.1264 8.04896 14.5273 8.2905 13.9568C8.52493 13.4077 8.85171 12.9156 9.27085 12.4948C9.68998 12.074 10.1802 11.7388 10.7272 11.5106C11.0113 11.3894 11.3026 11.3038 11.5939 11.2396V9.30693H13.4338V11.2396C13.7322 11.2967 14.0234 11.3894 14.3005 11.5106C14.8475 11.746 15.3377 12.074 15.7568 12.4948C16.1759 12.9156 16.5098 13.4077 16.7371 13.9568C16.9787 14.5273 17.0994 15.1264 17.0994 15.754H15.2595C15.2595 15.0123 14.9753 14.3205 14.4497 13.7999C14.1513 13.5004 13.8032 13.2864 13.4196 13.1509V18.6138C13.7179 18.6709 14.0092 18.7636 14.2863 18.8848Z"
          fill="#15384F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_12224">
          <rect
            width="24.8"
            height="32"
            fill="white"
            transform="translate(0.0996094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
