import React from "react";

import style from "./form-page-heading.scss";

export interface FormPageHeadingProps {
  title: string;
  description?: string;
}
export const FormPageHeading = ({
  title,
  description,
}: FormPageHeadingProps) => {
  return (
    <>
      <style jsx>{style}</style>
      <div className="form-page-heading">
        <h2 className="heading">{title}</h2>
        {description && <p className="description">{description}</p>}
      </div>
    </>
  );
};
