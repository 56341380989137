import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Address, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { commonTypeMapper, streetMapper } from "../../../../shared/form.utils";
import {
  QuoteForm,
  QuoteFormPageQuery,
  TimeSpentOption,
} from "../../../quote-form";
import {
  residencyTypesConst,
  timeSpentOptions,
} from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import {
  sendEstimateViewedEvent,
  timeSpentMapper,
} from "../../multi-page-quote-form.utils";
import {
  autocompleteAddress,
  parseAddress,
} from "../../../../forms/credit-profile/credit-profile-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
}

export const CurrentMovedIn: React.FC<PropTypes> = (props) => {
  const { fieldAdapter, formData, setValue, commonValues } = props;
  const [fieldValue, setFieldValue] = React.useState<TimeSpentOption>();

  const states = commonValues.states.map(commonTypeMapper);
  const residencyTypes = residencyTypesConst.map(commonTypeMapper);
  const streetTypes = [
    ...commonValues.streetTypes
      .filter((streetType) => streetType.common)
      .map(streetMapper),
    "",
    { label: "--------------", value: "" },
    "",
    ...commonValues.streetTypes.map(streetMapper),
  ];

  React.useEffect(() => {
    if (fieldValue !== undefined) {
      const selectedValue = timeSpentMapper(fieldValue);
      setValue("monthsAtAddress", `${selectedValue.months}`);
      setValue("yearsAtAddress", `${selectedValue.years}`);
      setFieldValue(undefined);

      if (selectedValue.years < 3 && !formData.previousAddress) {
        navigate("/quote/previous-address");
      } else {
        navigate("/quote/review");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CURRENT_MOVED_IN);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="How long have you lived at this address?" />

      <>
        <Field
          type="radio-list"
          label=""
          options={timeSpentOptions}
          onChange={(e) => {
            setFieldValue(e.currentTarget.value as TimeSpentOption);
          }}
        />
        <Address
          {...{
            search: autocompleteAddress,
            validate: parseAddress,
            fieldAdapter,
            formData,
            setValue,
            condition: false,
            states,
            residencyTypes,
            streetTypes,
            showMovedIn: false,
            showLivingSituation: false,
            optionalStreetType: true,
            mappings: {
              search: "previousAddress",
              unitNumber: "previousAddressUnit",
              number: "previousAddressStreetNumber",
              street: "previousAddressStreetName",
              streetType: "previousAddressStreetType",
              suburb: "previousAddressSuburb",
              state: "previousAddressState",
              postcode: "previousAddressPostcode",
              monthsAt: "monthsAtPreviousAddress",
              yearsAt: "yearsAtPreviousAddress",
              livingSituationAt: "livingSituationAtPreviousAddress",
              movedIn: "movedInPrevious",
            },
          }}
        />
      </>
    </div>
  );
};
