export const navigateToInvestmentCheck = ({
  fieldValue,
  isSecurable,
}: {
  fieldValue: string;
  isSecurable: boolean;
}) => {
  const investmentLivingSituations = [
    "renter",
    "boarder",
    "living-with-parents",
    "employer",
  ];

  if (investmentLivingSituations.includes(fieldValue) && isSecurable) {
    return true;
  }
  return false;
};
