import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
}

export const CarFound: React.FC<PropTypes> = ({ setValue }) => {
  const [fieldValue, setFieldValue] = React.useState("");

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CAR_FOUND);
  }, []);

  React.useEffect(() => {
    if (fieldValue !== "") {
      setValue("assetFound", fieldValue);

      if (fieldValue === "Yes") {
        setFieldValue("");
        navigate("/quote/car-manufacture-year");
      }
      if (fieldValue === "No") {
        setFieldValue("");
        navigate("/quote/car-age");
      }
    }
  }, [fieldValue, setValue]);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="Have you already found a vehicle that you want?" />

      <Field
        type="radio-list"
        label=""
        options={["Yes", "No"]}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
