import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardQuoteCopies } from "../../../../context-card/context-card.constants";
import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import loanPurposeStyle from "./loan-purpose.scss";
import { useRadioPurposes, useSelectPurposes } from "./loan-purpose.utils";
import { isSecuredPurpose } from "../../../quote-form.utils";
import { LoanParametersResponse } from "../../../../types/loan-parameters";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  loanParameters: LoanParametersResponse;
}

export const LoanPurpose: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  loanParameters,
}) => {
  const radioPurposes = useRadioPurposes(loanParameters.loanPurpose);
  const selectPurposes = useSelectPurposes(
    loanParameters.loanPurpose,
    radioPurposes
  );
  const isDisabled = !formData.loanPurpose;

  const nextPage = () => {
    if (
      isSecuredPurpose(
        formData.loanPurpose,
        loanParameters.loanPurpose,
        loanParameters.rateEstimate
      )
    ) {
      navigate("/quote/car-found");
    } else if (formData.loanPurpose) {
      navigate("/quote/loan-amount");
    }
  };

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.PURPOSE);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <style jsx>{loanPurposeStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <FormPageHeading title="Get your interest rate in under 5 minutes. Ready?" />
        </div>

        <div className="status-callout-wrapper">
          <StatusCallout
            type="coaching"
            theme="outline"
            message="Don't worry, getting an estimate won't affect your credit score."
          />
        </div>

        <div className="radio-wrapper">
          <Field
            type="radio-with-icon"
            label="What is the loan for?"
            options={radioPurposes}
            {...fieldAdapter({
              name: "loanPurpose",
            })}
          />
        </div>

        <div className="select-wrapper">
          <Field
            type="select"
            label="Other purposes"
            options={selectPurposes}
            placeholder="Select..."
            {...fieldAdapter({
              name: "loanPurpose",
            })}
          />
        </div>

        <div className="context-card-wrapper">
          <ContextCard
            copy={ContextCardQuoteCopies.SECOND_LOAN}
            form="quote"
            centerCard={true}
          />
        </div>

        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              Get Started
            </button>
          </Button>
        </div>
      </div>
    </div>
  );
};
