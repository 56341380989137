import { RouteComponentProps } from "@reach/router";
import { toCurrency, UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { isValidLengthWithoutDecimals } from "../../../../shared/form.utils";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import { navigate } from "gatsby";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
  formState: UseFormTools<QuoteForm>["formState"];
}

export const Income: React.FC<PropTypes> = (props) => {
  const { fieldAdapter, formData, commonValues, formState } = props;
  const isDisabled = !formData.income || formState.income.errors.length !== 0;

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.INCOME);
  }, []);

  return (
    <div className="multi-page submit-screen">
      <style jsx>{style}</style>
      <div className="form-contents">
        <FormPageHeading title="Finally, can you verify your income?" />
        <Field
          type="currency"
          label="How much do you earn annually (before tax)?"
          hasCommas={true}
          {...fieldAdapter({
            name: "income",
            min: [
              commonValues.minEligibleIncome,
              `Total yearly income before tax must be at least ${toCurrency(
                commonValues.minEligibleIncome,
                false
              )}`,
            ],
            required: "Total yearly income before tax is required",
            validate: (value) =>
              isValidLengthWithoutDecimals(value, 10) ||
              "Total yearly income before tax cannot be more than 8 digits and 2 decimal places",
          })}
        />
        <p className="field-subtext">Please include income from all sources.</p>
      </div>

      <div className="button-wrapper">
        <Button theme="navy" disabled={isDisabled} fullWidth={true}>
          <button
            disabled={isDisabled}
            onClick={() => navigate("/quote/review")}
          >
            Back to review
          </button>
        </Button>
      </div>
    </div>
  );
};
