import React from "react";
import { Loading } from "../../../loading/loading.component";
import { useGetRateEstimateState } from "../../../../redux/rate-estimate/rate-estimate.hooks";
import { useDispatch } from "react-redux";
import { FetchStatus } from "../../../../shared/redux.consts";
import { fetchRateEstimate } from "../../../../redux/rate-estimate/rate-estimate.thunk";
import { RateEstimateStatus } from "../../../../redux/rate-estimate/rate-estimate.consts";
import { redirect } from "../../../../shared/browser.utils";
import { BRAND_WEBSITE } from "../../../../shared/url.consts";
import { navigate } from "gatsby";
import { clearRateEstimateState } from "../../../../redux/rate-estimate/rate-estimate.slice";

export const QuoteSubmitted: React.FC = () => {
  const dispatch = useDispatch();
  const rateEstimateState = useGetRateEstimateState();
  const rateEstimateStatus = rateEstimateState.rateEstimateResponse?.status;
  const PENDING_DELAY = 1000;

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (
      rateEstimateState.wisrApplicationId &&
      rateEstimateState.getStatus !== FetchStatus.LOADING
    ) {
      const wisrApplicationId = rateEstimateState.wisrApplicationId;

      if (
        rateEstimateState.getStatus === FetchStatus.IDLE ||
        !rateEstimateStatus
      ) {
        dispatch(
          fetchRateEstimate({
            wisrApplicationId: wisrApplicationId,
          })
        );
      } else if (rateEstimateStatus === RateEstimateStatus.PENDING) {
        timeout = setTimeout(() => {
          dispatch(
            fetchRateEstimate({
              wisrApplicationId: wisrApplicationId,
            })
          );
        }, PENDING_DELAY);
      } else if (rateEstimateStatus === RateEstimateStatus.NO_RECORD) {
        dispatch(clearRateEstimateState());
        navigate("/quote/no-match");
      } else {
        redirect(`${BRAND_WEBSITE}/apply/estimate?appid=${wisrApplicationId}`);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    dispatch,
    rateEstimateState.getStatus,
    rateEstimateState.wisrApplicationId,
    rateEstimateStatus,
  ]);

  return <Loading />;
};
