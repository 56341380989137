import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { isValidDate, olderThan18, UseFormTools } from "@wisr/common";
import { Button, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React, { FormEventHandler } from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import formStyle from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import componentStyle from "./no-match.scss";
import { UserDetails } from "./user-details/user-details";

interface NoMatchPropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
  setValue: UseFormTools<QuoteForm>["setValue"];
  multipleSearchAttempts?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const NoMatch = ({
  formData,
  commonValues,
  fieldAdapter,
  setValue,
  multipleSearchAttempts,
  onSubmit,
}: NoMatchPropTypes) => {
  const [hasEdited, setHasEdited] = React.useState(false);
  const handleNavigationClick = () => navigate("/quote/your-id");

  const isValidAddress =
    formData.addressPostcode &&
    formData.addressState &&
    formData.addressStreetName &&
    formData.addressStreetNumber &&
    formData.addressStreetType &&
    formData.addressSuburb;

  const isDisabled =
    !formData.firstName ||
    !formData.lastName ||
    !olderThan18(formData.dateOfBirth) ||
    !isValidDate(formData.dateOfBirth) ||
    !isValidAddress;

  const calloutMessage = multipleSearchAttempts ? (
    <>
      <p>
        Your rate is tailored based on your credit score. The information you
        provided may not match up with the information that the credit bureaus
        have on file. This is a soft enquiry and will not impact your credit
        score.
      </p>
      <p>Double check that your details are correct.</p>
      <ul>
        <li>Is your name spelt correctly?</li>
        <li>Is your date of birth correct?</li>
        <li>
          Have you moved house recently? The credit bureau may have your
          previous address on file. Try using that one.
        </li>
        <li>
          Have you renewed your license, passport or Medicare card recently? The
          credit bureau may not have recieved your updated one. Try punching in
          your old digits.
        </li>
        <li>
          You can also try a different form of identity document from out list
          of Driver License, Passport or Medicare card.
        </li>
      </ul>
    </>
  ) : (
    <p>
      Your rate is tailored based on your credit score. The information you
      provided may not match up with the information that the credit bureaus
      have on file. This is a soft enquiry and will not impact your credit
      score.
    </p>
  );
  const navigateButtonText = multipleSearchAttempts
    ? "Try another ID"
    : "My Details are correct";

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.NO_MATCH);
  }, []);

  return (
    <form className="multi-page no-match-screen" noValidate onSubmit={onSubmit}>
      <style jsx>{formStyle}</style>
      <style jsx>{componentStyle}</style>
      <div className="form-contents">
        <FormPageHeading title="Sorry, we couldn’t match the details you provided" />

        <StatusCallout
          type="info"
          theme="outline"
          heading="Review your information"
          message={calloutMessage}
        />

        <div className="about-you-section">
          <span className="paragraph-title">About you</span>
          <UserDetails
            type="name"
            fieldAdapter={fieldAdapter}
            formData={formData}
            setValue={setValue}
            setHasEdited={setHasEdited}
            commonValues={commonValues}
          />

          <UserDetails
            type="dob"
            fieldAdapter={fieldAdapter}
            formData={formData}
            setValue={setValue}
            setHasEdited={setHasEdited}
            commonValues={commonValues}
          />

          <UserDetails
            type="address"
            fieldAdapter={fieldAdapter}
            formData={formData}
            setValue={setValue}
            setHasEdited={setHasEdited}
            commonValues={commonValues}
          />
        </div>

        <div className="button-wrapper resubmit">
          <Button
            button="secondary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={!hasEdited || isDisabled}
          >
            <button
              className="next-page-button"
              type="submit"
              disabled={!hasEdited || isDisabled}
            >
              re-submit my details
            </button>
          </Button>
        </div>

        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              type="button"
              onClick={handleNavigationClick}
              disabled={isDisabled}
            >
              {navigateButtonText}
            </button>
          </Button>
        </div>
      </div>
    </form>
  );
};
