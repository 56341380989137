import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { RateEstimatePrefillState } from "../../types/rate-estimate-prefill";
import { selectRateEstimatePrefillState } from "./rate-estimate-prefill.slice";

export const useGetRateEstimatePrefillState = () => {
  return useSelector<AppStore, RateEstimatePrefillState>(
    selectRateEstimatePrefillState
  );
};
