import React from "react";
import Lottie from "react-lottie";

import clock from "../../assets/images/clock.svg";
import lightbulbOn from "../../assets/images/lightbulb-on.svg";
import star from "../../assets/images/star.svg";
import { TrustPilotWidget } from "../../trust-pilot/trust-pilot-widget";
import style from "./loading.scss";
import loadingBrain from "./loading-brain.json";
import { Wrapper } from "@wisr/react-ui";

export const Loading = () => {
  return (
    <Wrapper>
      <style jsx>{style}</style>
      <div className="quote-form-loading flex-column">
        <div className="heading flex-column">
          <Lottie
            width="100px"
            options={{
              animationData: loadingBrain,
              loop: true,
              autoplay: true,

              rendererSettings: {
                preserveAspectRatio: "xMidYMid meet",
              },
            }}
          />
          <h4>Getting your rate estimate...</h4>
        </div>

        <div className="info-column">
          <div className="flex-column">
            <img src={lightbulbOn} alt="lightbulb-icon" />

            <p>
              Pay off extra with
              <br />
              Wisr App
            </p>
          </div>

          <div className="flex-column">
            <img src={star} alt="star-icon" />
            <p>
              No early repayment
              <br />
              or exit fees
            </p>
          </div>

          <div className="flex-column">
            <img src={clock} alt="clock-icon" />
            <p>
              Same-day settlement is
              <br />
              possible with Wisr
            </p>
          </div>
        </div>

        <TrustPilotWidget
          position="center"
          backroundColour="brand-teal-100"
          wisrLogo={true}
        />
      </div>
    </Wrapper>
  );
};
