import { RouteComponentProps } from "@gatsbyjs/reach-router";
import {
  combineValidators,
  createValidator,
  isValidDate,
  olderThan18,
  UseFormTools,
} from "@wisr/common";
import { Address, Field, Icon } from "@wisr/react-ui";
import React from "react";

import { QuoteFormPageQuery } from "../../../../../../generated/graphql-types";
import {
  commonTypeMapper,
  streetMapper,
} from "../../../../../shared/form.utils";
import { QuoteForm } from "../../../../quote-form";
import { residencyTypesConst } from "../../../../quote-form.constants";
import userDetailsStyle from "./user-details.scss";
import { showLabel, showValue } from "./user-details.utils";
import {
  autocompleteAddress,
  parseAddress,
} from "../../../../../forms/credit-profile/credit-profile-form.utils";

export type userDetailTypes = "name" | "dob" | "address";
interface NoMatchPropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
  setValue: UseFormTools<QuoteForm>["setValue"];
  setHasEdited: React.Dispatch<React.SetStateAction<boolean>>;
  type: userDetailTypes;
}

export const UserDetails = ({
  formData,
  fieldAdapter,
  commonValues,
  setValue,
  setHasEdited,
  type,
}: NoMatchPropTypes) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const states = commonValues.states.map(commonTypeMapper);
  const residencyTypes = residencyTypesConst.map(commonTypeMapper);
  const streetTypes = [
    ...commonValues.streetTypes
      .filter((streetType) => streetType.common)
      .map(streetMapper),
    "",
    { label: "--------------", value: "" },
    "",
    ...commonValues.streetTypes.map(streetMapper),
  ];

  const olderThan18Validator = createValidator(
    olderThan18,
    "You must be at least 18 years old"
  );
  const isValidDateValidator = createValidator(
    isValidDate,
    "Please enter a valid date of birth."
  );

  const showFormFields = (userDetailType: userDetailTypes) => {
    switch (userDetailType) {
      case "dob":
        return (
          <Field
            type="date"
            label="Date of birth"
            {...fieldAdapter({
              name: "dateOfBirth",
              required: "Date of birth is required",
              validate: (v) =>
                combineValidators(
                  v,
                  isValidDateValidator,
                  olderThan18Validator
                ),
            })}
          />
        );

      case "address":
        return (
          <Address
            {...{
              search: autocompleteAddress,
              validate: parseAddress,
              fieldAdapter,
              formData,
              setValue,
              states,
              residencyTypes,
              streetTypes,
              showMovedIn: false,
              showLivingSituation: false,
              optionalStreetType: true,
              mappings: {
                search: "address",
                unitNumber: "addressUnit",
                number: "addressStreetNumber",
                street: "addressStreetName",
                streetType: "addressStreetType",
                suburb: "addressSuburb",
                state: "addressState",
                postcode: "addressPostcode",
                monthsAt: "monthsAtAddress",
                yearsAt: "yearsAtAddress",
                livingSituationAt: "livingSituationAtAddress",
                movedIn: "movedIn",
              },
            }}
          />
        );
      default:
        return null;
    }
  };

  const handleEditClick = () => {
    setHasEdited(true);
    setIsEditing(true);
  };

  return (
    <>
      <style jsx>{userDetailsStyle}</style>
      <div className={`user-details ${type} ${isEditing ? "editing" : ""}`}>
        {isEditing ? (
          showFormFields(type)
        ) : (
          <>
            <label>{showLabel(type)}</label>
            <span>{showValue({ type, formData })}</span>
            {type !== "name" && (
              <div className="icon-wrapper" onClick={() => handleEditClick()}>
                <Icon name="action-edit-outline" size="sm" legacy={true} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
