export const maritalStatusOptions = [
  "Single",
  "Married",
  "De Facto",
  "Separated",
  "Divorced",
  "Widowed",
  "Other",
];

export const CONSOLIDATION_PURPOSES = [
  "consolidation",
  "consolidation-debtbustr",
];
