import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { commonTypeMapper } from "../../../../shared/form.utils";
import { QuoteForm } from "../../../quote-form";
import { residencyTypesConst } from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import { navigateToInvestmentCheck } from "./current-living-situation.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
  isSecurable: boolean;
}

export const CurrentLivingSituation: React.FC<PropTypes> = ({
  setValue,
  isSecurable,
}) => {
  const residencyTypes = residencyTypesConst.map(commonTypeMapper);
  const [fieldValue, setFieldValue] = React.useState("");

  React.useEffect(() => {
    const shouldShowInvestmentStep = navigateToInvestmentCheck({
      fieldValue,
      isSecurable,
    });

    if (fieldValue !== "") {
      setValue("livingSituationAtAddress", fieldValue);
      setFieldValue("");

      if (shouldShowInvestmentStep) {
        navigate("/quote/investment-property");
      } else {
        navigate("/quote/review");
      }
    }
  }, [fieldValue, isSecurable, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.LIVING_SITUATION);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="What is your current living situation?" />
      <Field
        type="radio-list"
        label=""
        options={residencyTypes}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
