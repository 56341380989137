import React from "react";
import { capitalise, toCurrency, UseFormTools } from "@wisr/common";

import { QuoteForm } from "../../../../quote-form";
import { Purpose } from "../../../../../types/loan-parameters";
import { livingSituationOptions } from "../../../../../application/multi-page-application-form/form-pages/living-situation/living-situation.constants";
import { getIsPurposeSecurableByKey } from "../../../../../application/multi-page-application-form/multi-page-application-form.utils";
import { employmentStatusOptions } from "../../../../../application/multi-page-application-form/form-pages/employment-and-income/employment-and-income.constants";
import { getTimeSpentString } from "../../../../../application/multi-page-application-form/form-pages/review-your-details/review-your-details.utils";
import { isValueYes } from "../../../../../redux/loan-application/loan-application.utils";
import { StatusCallout } from "@wisr/react-ui";
import { BRAND_WEBSITE } from "../../../../../shared/url.consts";

export interface ReviewField {
  label: string;
  value: string | JSX.Element;
  editable?: boolean;
  editPath?: string;
}

export const getAboutYou = (formData: UseFormTools<QuoteForm>["formData"]) => [
  {
    label: "",
    value: (
      <div className="status-callout-wrapper">
        <StatusCallout
          type="coaching"
          theme="outline"
          message={
            <>
              For security reasons please contact us to make to updates to your
              name, mobile or email
              <br />
              <br />
              <a
                href={`${BRAND_WEBSITE}/contact-us`}
                rel="noreferrer"
                target="_blank"
              >
                Contact us
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    label: "Name",
    value: `${formData.firstName} ${formData.lastName}`,
  },
  {
    label: "Mobile",
    value: formData.mobile,
  },
  {
    label: "Email",
    value: formData.email,
  },
  {
    label: "Relationship status",
    value: capitalise(formData.maritalStatus),
    editable: true,
    editPath: "relationship-status",
  },
];

export const getLivingSituation = (
  formData: UseFormTools<QuoteForm>["formData"],
  loanPurposes: Purpose[]
) => {
  const livingSituation =
    livingSituationOptions.find((livingSituationOption) => {
      return formData.livingSituationAtAddress === livingSituationOption.value;
    })?.label ?? formData.livingSituationAtAddress;

  let base = [
    {
      label: "",
      value: <p className="subheading">Current Address</p>,
    },
    {
      label: "Residential Address",
      value: formData.address,
      editable: true,
      editPath: "current-address",
    },
    {
      label: "Current living situation",
      value: livingSituation,
      editable: true,
      editPath: "current-living-situation",
    },
    {
      label: "How long have you lived here?",
      value: getTimeSpentString({
        years: formData.yearsAtAddress,
        months: formData.monthsAtAddress,
      }),
      editable: true,
      editPath: "current-moved-in",
    },
  ];

  if (formData.previousAddress) {
    base = [
      ...base,
      {
        label: "",
        value: <p className="subheading">Previous Address</p>,
      },
      {
        label: "Residential Address",
        value: formData.previousAddress,
        editable: true,
        editPath: "previous-address",
      },
      {
        label: "How long were you at this address?",
        value: getTimeSpentString({
          years: formData.yearsAtPreviousAddress,
          months: formData.monthsAtPreviousAddress,
        }),
        editable: true,
        editPath: "previous-moved-in",
      },
    ];
  }

  if (getIsPurposeSecurableByKey(formData.loanPurpose, loanPurposes)) {
    base = [
      ...base,
      {
        label: "",
        value: <p className="subheading">Investment Property</p>,
      },
      {
        label: "Do you own an investment property?",
        value: capitalise(formData.ownsInvestmentProperty),
        editable: true,
        editPath: "investment-property",
      },
    ];

    if (isValueYes(formData.ownsInvestmentProperty)) {
      base = [
        ...base,
        {
          label: "Is your investment property mortgaged?",
          value: capitalise(formData.investmentPropertyMortgaged),
          editable: true,
          editPath: "mortgaged-investment-property",
        },
      ];
    }
  }

  return base;
};

export const getYourEmploymentAndIncome = (
  formData: UseFormTools<QuoteForm>["formData"]
) => {
  const employmentStatus =
    employmentStatusOptions.find(
      (option) => option.value === formData.employmentStatus
    )?.label ?? formData.employmentStatus;

  const previousEmploymentStatus =
    employmentStatusOptions.find(
      (option) => option.value === formData.previousEmploymentStatus
    )?.label ?? formData.previousEmploymentStatus;

  let base = [
    {
      label: "",
      value: <p className="subheading">Current Employment</p>,
    },
    {
      label: "Status",
      value: employmentStatus,
      editable: true,
      editPath: "current-employment-status",
    },
    {
      label: "Time employed",
      value: getTimeSpentString({
        years: formData.yearsAtEmployer,
        months: formData.monthsAtEmployer,
      }),
      editable: true,
      editPath: "current-employment-length",
    },
    {
      label: "Annual income",
      value: toCurrency(parseInt(formData.income), false),
      editable: true,
      editPath: "income",
    },
  ];

  if (formData.previousEmploymentStatus) {
    base = [
      ...base,
      {
        label: "",
        value: <p className="subheading">Previous Employment</p>,
      },
      {
        label: "Status",
        value: previousEmploymentStatus,
        editable: true,
        editPath: "previous-employment-status",
      },
      {
        label: "Time employed",
        value: getTimeSpentString({
          years: formData.yearsAtPreviousEmployer,
          months: formData.monthsAtPreviousEmployer,
        }),
        editable: true,
        editPath: "previous-employment-length",
      },
    ];
  }

  return base;
};

export const getReviewFields = (
  formData: UseFormTools<QuoteForm>["formData"],
  loanPurposes: Purpose[]
) => ({
  "About you": getAboutYou(formData),

  "Your living situation": getLivingSituation(formData, loanPurposes),

  "Your employment & income": getYourEmploymentAndIncome(formData),
});
