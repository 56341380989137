import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
}

export const CurrentEmploymentStatus: React.FC<PropTypes> = ({
  setValue,
  commonValues,
}) => {
  const employmentOptions = commonValues.employment.map((employment) => ({
    label: employment.description,
    value: employment.code,
  }));
  const [fieldValue, setFieldValue] = React.useState("");

  React.useEffect(() => {
    if (fieldValue !== "") {
      setValue("employmentStatus", fieldValue);
      setFieldValue("");
      navigate("/quote/current-employment-length");
    }
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CURRENT_EMPLOYMENT_STATUS);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="Now, onto work. What is your employment status?" />

      <Field
        type="radio-list"
        label=""
        options={employmentOptions}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
