import React from "react";

export const GapFinanceIcon = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4223_2058)">
        <path
          d="M206 181C260.8 181 298.167 136 310 113.5V233H90V113.5C105.833 136 151.2 181 206 181Z"
          className="icon-fill"
        />
        <path
          d="M371.625 158.996H400L327.297 100.882C327.384 100.588 327.514 100.294 327.602 100H304.675H304.326V100.84C288.635 141.486 247.968 170.502 200.371 170.502C152.773 170.502 111.583 141.109 96.1098 100H73.183C73.183 100 73.183 100.084 73.183 100.126V100.462L0 158.996H28.3753V227.021H16.3452V248.352H73.2265V300H95.3689V248.352H304.326V300H326.468V248.352H383.393V227.021H371.625V158.996ZM304.326 227.021H95.3689V141.528C119.647 171.887 157.655 191.539 200.414 191.539C243.173 191.539 280.048 172.517 304.37 142.956V227.021H304.326Z"
          fill="#15384F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4223_2058">
          <rect
            width="400"
            height="200"
            fill="white"
            transform="translate(0 100)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
