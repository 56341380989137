import React from "react";

export const MotorbikeIcon = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4223_2008)">
        <path
          d="M341.5 271L322 158L282 133.5L197.5 119C174.5 125.333 128.5 138.4 128.5 140V206.5C145.167 219.167 179.2 244.9 182 246.5C185.5 248.5 272 278.5 275 279.5C277.4 280.3 320.333 274.167 341.5 271Z"
          className="icon-fill"
        />
        <path
          d="M208.932 178.228C208.932 185.857 205.933 192.767 201.173 197.983C253.788 243.165 310.836 253.14 338.675 255.162L324.658 173.534H208.475C208.736 175.099 208.932 176.664 208.932 178.228Z"
          fill="#15384F"
        />
        <path
          d="M340.957 268.522C312.01 267.088 248.572 257.504 189.96 205.606C186.7 206.845 183.244 207.627 179.528 207.627C163.294 207.627 150.189 194.458 150.189 178.289C150.189 162.12 163.359 148.95 179.528 148.95C189.047 148.95 197.392 153.513 202.738 160.555H322.311L321.072 153.448C316.508 144.907 310.771 131.737 306.403 124.501C269.696 63.2148 200.522 41.178 137.541 71.4949C92.2937 93.3361 66.0843 130.108 59.4993 179.853C55.783 207.953 58.8473 235.597 70.1265 262.002C71.7564 265.784 74.1688 267.87 78.1458 269.239C147.386 292.971 216.495 316.833 285.67 340.761C288.799 341.869 290.56 341.348 292.711 338.805C307.511 321.267 341.023 276.607 342.131 275.042L341.023 268.392L340.957 268.522Z"
          fill="#15384F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4223_2008">
          <rect
            width="284.066"
            height="283.349"
            fill="white"
            transform="translate(58 58)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
