import React from "react";

export const CarIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1665 0.66496V14.2785H32.5V26.2411H28.8749V31.5546C28.8749 31.801 28.6737 32.0002 28.4243 32.0002H25.2719C25.0231 32.0002 24.8213 31.801 24.8213 31.5546V26.2411H8.60634V31.5546C8.60634 31.801 8.40513 32.0002 8.15632 32.0002H5.00332C4.75451 32.0002 4.55273 31.801 4.55273 31.5546V26.2411H0.5V14.2785H3.8329V0.66496C3.8329 0.297643 4.13203 0 4.49993 0H28.5001C28.8685 0 29.1665 0.297643 29.1665 0.66496Z"
        fill="#15384F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-fill"
        d="M7.25586 13.9408H26.1727V2.57019H7.25586V13.9408Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-fill"
        d="M9.28353 19.626C9.28353 21.2881 7.92213 22.6358 6.24304 22.6358C4.56453 22.6358 3.20312 21.2881 3.20312 19.626C3.20312 17.9638 4.56453 16.6161 6.24304 16.6161C7.92213 16.6161 9.28353 17.9638 9.28353 19.626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-fill"
        d="M30.2269 19.626C30.2269 21.2881 28.8656 22.6358 27.1867 22.6358C25.5078 22.6358 24.1465 21.2881 24.1465 19.626C24.1465 17.9638 25.5078 16.6161 27.1867 16.6161C28.8656 16.6161 30.2269 17.9638 30.2269 19.626"
      />
    </svg>
  );
};
