import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, Icon, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import { QuoteFormPageQuery } from "../../../../../generated/graphql-types";
import {
  Events,
  RateEstimatePages,
} from "../../../../shared/analytics/analytics.consts";
import { quoteAmplitudeHelper } from "../../../../shared/analytics/analytics.utils";
import { BRAND_WEBSITE } from "../../../../shared/url.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import assetSecurityStyle from "./asset-security.scss";
import {
  getAssetSecurityCalloutCopy,
  getAssetSecurityOptions,
  getAssetSecurityQuestionCopy,
} from "./asset-security.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
}

export const AssetSecurity: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  commonValues,
}) => {
  const { rateEstimateSecureQuestion } = useFlags();
  const loanPurpose =
    commonValues.flexibleLoanPurposes.loanPurpose.find(
      (purpose) => purpose.key === formData.loanPurpose
    )?.description ?? "asset";
  const isDisabled = !formData.assetSecurity;
  const nextPageUrl = "/quote/loan-amount";

  React.useEffect(() => {
    quoteAmplitudeHelper.sendEvent(
      `${Events.VIEWED_ESTIMATE} - ${RateEstimatePages.ASSET_SECURITY}`,
      undefined,
      { "Request Secure Loan": rateEstimateSecureQuestion }
    );
  }, [rateEstimateSecureQuestion]);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <style jsx>{assetSecurityStyle}</style>
      <div className="form-contents">
        <FormPageHeading
          title={getAssetSecurityQuestionCopy(
            rateEstimateSecureQuestion,
            loanPurpose
          )}
        />

        <div className="input-wrapper">
          <Field
            type="radio-button"
            label=""
            options={getAssetSecurityOptions(rateEstimateSecureQuestion)}
            {...fieldAdapter({
              name: "assetSecurity",
              required: "Asset security is required",
            })}
          />
        </div>
        <div className="callout-wrapper">
          <StatusCallout
            type="coaching"
            message={getAssetSecurityCalloutCopy(
              formData.assetSecurity,
              rateEstimateSecureQuestion,
              commonValues.flexibleLoanPurposes.monthlyAccountFee
            )}
          />
        </div>
        <a
          href={`${BRAND_WEBSITE}/smart-guides/secured-loans-guide`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Learn more</p>
          <Icon name="action-help-outline" size="sm" />
        </a>
        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => navigate(nextPageUrl)}
              disabled={isDisabled}
            >
              next
            </button>
          </Button>
        </div>
      </div>
    </div>
  );
};
