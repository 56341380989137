import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import dayjs from "dayjs";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
  formState: UseFormTools<QuoteForm>["formState"];
}

export const CarManufactureYear: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
}) => {
  const isDisabled =
    !formData.assetYear || formState.assetYear.errors.length > 0;

  React.useEffect(() => {
    if (formData.assetYear === "0000") {
      setValue("assetYear", "");
    }

    return () => {
      if (formState.assetYear.errors.length !== 0) {
        setValue("assetYear", "0000");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CAR_YEAR);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="What year was the vehicle manufactured?" />

      <Field
        type="input"
        label="Year of manufacture"
        placeholder={`e.g. ${dayjs().year()}`}
        {...fieldAdapter({
          name: "assetYear",
          required: "Year of manufacture is required",
          minLength: [4, "Please enter a valid year"],
          maxLength: [4, "Please enter a valid year"],
        })}
      />
      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => navigate("/quote/car-seller-type")}
            disabled={isDisabled}
          >
            next
          </button>
        </Button>
      </div>
    </div>
  );
};
