import React from "react";

import { TermsResponse } from "../../types/terms";
import {
  defaultCommunicationConsentSection,
  defaultTermsSection,
} from "./terms.consts";
import { addTermHtml } from "../../application/multi-page-application-form/form-pages/review-your-details/terms-list/terms-list.utils";

interface TermsJSX {
  termsSection: JSX.Element;
  communicationConsentSection: JSX.Element;
}

export const convertTermsToJSX = (
  terms: TermsResponse | null | undefined
): TermsJSX => {
  if (!terms) {
    return {
      termsSection: defaultTermsSection,
      communicationConsentSection: defaultCommunicationConsentSection,
    };
  }

  const preamble = terms.sections.find((section) => section.name === "Preamble")
    ?.clauses[0].summary;
  const termsConsent = terms.sections.find(
    (section) => section.name === "Terms"
  );

  const termList = termsConsent?.clauses.map((clause) => {
    return addTermHtml(clause);
  });

  const listItems = termList?.map((termHtmlString, index) => (
    <li dangerouslySetInnerHTML={{ __html: termHtmlString }} key={index}></li>
  ));

  const communicationConsentString =
    termsConsent?.clauses.find((clause) => {
      if (clause.refId === "electronic-communication-consent") {
        return clause;
      }
    })?.details ?? "";

  const lines = communicationConsentString.replace(/\n\n/g, "\n").split("\n");
  const additionalText = lines.slice(0, 2).join("\n");
  const items = lines.slice(2).filter((item) => item.trim().startsWith("1."));

  const htmlList = `<p>${additionalText
    .split("\n")
    .join("</p>\n<p>")}</p>\n<ol>\n${items
    .map((item) => `<li>${item.trim().substring(3)}</li>`)
    .join("\n")}\n</ol>`;

  return {
    termsSection: (
      <>
        {preamble && <p className="bold">{preamble}</p>}
        <p className="bold">By getting an estimate:</p>
        <ul>{listItems}</ul>
      </>
    ),
    communicationConsentSection: (
      <div
        className="communication-consent"
        dangerouslySetInnerHTML={{ __html: htmlList }}
      ></div>
    ),
  };
};
