import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Address, Button } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardQuoteCopies } from "../../../../context-card/context-card.constants";
import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { commonTypeMapper, streetMapper } from "../../../../shared/form.utils";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { residencyTypesConst } from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import { CONSOLIDATION_PURPOSES } from "../../multi-page-quote-form.constants";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import {
  autocompleteAddress,
  parseAddress,
} from "../../../../forms/credit-profile/credit-profile-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
}

export const CurrentAddress: React.FC<PropTypes> = (props) => {
  const { fieldAdapter, formData, setValue, commonValues } = props;
  const states = commonValues.states.map(commonTypeMapper);
  const residencyTypes = residencyTypesConst.map(commonTypeMapper);
  const streetTypes = [
    ...commonValues.streetTypes
      .filter((streetType) => streetType.common)
      .map(streetMapper),
    "",
    { label: "--------------", value: "" },
    "",
    ...commonValues.streetTypes.map(streetMapper),
  ];

  const isDisabled = !(
    formData.addressPostcode &&
    formData.addressState &&
    formData.addressStreetName &&
    formData.addressStreetNumber &&
    formData.addressSuburb
  );

  const getContextCardCopy = () => {
    if (formData.loanPurpose === "car") {
      return ContextCardQuoteCopies.GOOD_COMP_CAR;
    } else if (CONSOLIDATION_PURPOSES.includes(formData.loanPurpose)) {
      return ContextCardQuoteCopies.GOOD_COMP_DEBT;
    } else if (formData.loanPurpose === "home-improvements") {
      return ContextCardQuoteCopies.GOOD_COMP_RENO;
    } else {
      return ContextCardQuoteCopies.GOOD_COMP_OTHER;
    }
  };

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CURRENT_ADDRESS);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <div className="form-contents current-address">
        <FormPageHeading
          title="Where are you living?"
          description="Start typing your address below. If it does not appear, please enter it manually."
        />

        <Address
          {...{
            search: autocompleteAddress,
            validate: parseAddress,
            fieldAdapter,
            formData,
            setValue,
            states,
            residencyTypes,
            streetTypes,
            showMovedIn: false,
            showLivingSituation: false,
            optionalStreetType: true,
            mappings: {
              search: "address",
              unitNumber: "addressUnit",
              number: "addressStreetNumber",
              street: "addressStreetName",
              streetType: "addressStreetType",
              suburb: "addressSuburb",
              state: "addressState",
              postcode: "addressPostcode",
              monthsAt: "monthsAtAddress",
              yearsAt: "yearsAtAddress",
              livingSituationAt: "livingSituationAtAddress",
              movedIn: "movedIn",
            },
          }}
        />
      </div>

      <ContextCard copy={getContextCardCopy()} form="quote" />

      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => navigate("/quote/current-moved-in")}
            disabled={isDisabled}
          >
            next
          </button>
        </Button>
      </div>
    </div>
  );
};
