import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { RateEstimateTermsState } from "../../types/terms";
import { selectRateEstimateTermsState } from "./rate-estimate-terms.slice";

export const useGetRateEstimateTermsState = () => {
  return useSelector<AppStore, RateEstimateTermsState>(
    selectRateEstimateTermsState
  );
};
