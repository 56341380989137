import { UseFormTools } from "@wisr/common";

import { IdentityTypes } from "./fields/identification-fields.options";
import { QuoteForm } from "../../../quote-form";

interface DisabledSubmitProps {
  formDataIdentityIndex: string | string[];
  formData: UseFormTools<QuoteForm>["formData"];
  formState: UseFormTools<QuoteForm>["formState"];
}
export const disabledSubmit = ({
  formDataIdentityIndex,
  formData,
  formState,
}: DisabledSubmitProps) => {
  if (formDataIdentityIndex === IdentityTypes.DRIVERS_LICENCE) {
    if (
      !formData.driversLicenceState ||
      !formData.driverLicenceNumber ||
      !formData.driverLicenceCardNumber ||
      formState.driverLicenceNumber.errors.length > 0 ||
      formState.driverLicenceCardNumber.errors.length > 0
    ) {
      return true;
    }
    return false;
  }
  if (formDataIdentityIndex === IdentityTypes.MEDICARE_CARD) {
    if (
      !formData.medicareNumber ||
      !formData.medicareColour ||
      !formData.medicareExpiryDateGreen ||
      !formData.medicareReferenceNumber ||
      formState.medicareNumber.errors.length > 0
    ) {
      return true;
    }
    return false;
  }
  if (formDataIdentityIndex === IdentityTypes.PASSPORT) {
    if (
      !formData.passportNumber ||
      formState.passportNumber.errors.length > 0
    ) {
      return true;
    }
    return false;
  }
  return false;
};
