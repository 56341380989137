import { FormState, isBrowser } from "@wisr/common";

import {
  QuoteForm,
  QuoteFormPageQuery,
  QuoteSubmitAnalytics,
  TrackUserInteractionProps,
  UpdateSessionStorageProps,
} from "./quote-form";
import { initialFieldTrackingState } from "./quote-form.constants";
import dayjs from "dayjs";
import {
  quoteAmplitudeHelper,
  sendGaEvent,
  sendGtmEvent,
} from "../shared/analytics/analytics.utils";
import { Events, LoanStatus } from "../shared/analytics/analytics.consts";
import {
  LoanParametersResponse,
  Purpose,
  RateEstimate,
} from "../types/loan-parameters";

const updateSessionStorage = ({
  state,
  fieldName,
  event,
}: UpdateSessionStorageProps) => {
  const triggers = event && {
    selectField: event.type === "mouseup" || event.type === "touchend",
    inputField: event.type === "keyup" && event.currentTarget.value.length >= 1,
  };

  if ((triggers && (triggers.selectField || triggers.inputField)) || !event) {
    state[fieldName] = true;
    window.sessionStorage.setItem("fieldTouchedState", JSON.stringify(state));
  }
};

export const trackUserInteraction = ({
  fieldName,
  event,
}: TrackUserInteractionProps) => {
  if (!isBrowser()) return;
  const currentFieldTrackingState =
    window.sessionStorage.getItem("fieldTouchedState");

  const state = currentFieldTrackingState
    ? JSON.parse(currentFieldTrackingState)
    : initialFieldTrackingState;

  updateSessionStorage({
    state,
    fieldName,
    event,
  });
};

export const getErrorsFromState = <T extends FormState<QuoteForm>>(
  state: T
) => {
  return Object.entries(state).reduce((acc, [key, value]) => {
    if (value.errors.length > 0) {
      acc[key] = value.errors;
    }
    return acc;
  }, {} as { [key: string]: string[] });
};

export const sendQuoteSubmitAnalytics = ({
  loanPurpose,
  loanAmount,
  title,
  addressState,
  addressPostcode,
  employmentStatus,
  dateOfBirth,
  identityType1,
  email,
}: QuoteSubmitAnalytics) => {
  quoteAmplitudeHelper.sendEvent(
    Events.COMPLETED_ESTIMATE,
    {
      "Loan application status": LoanStatus.QUOTE_SUBMITTED,
      "Loan purpose": loanPurpose,
      "Loan amount": loanAmount,
      "Id type": identityType1 || "None",
      Aggregator: "None set",
    },
    {
      "Loan application status": LoanStatus.QUOTE_SUBMITTED,
      Title: title,
      "Date of birth": dateOfBirth,
      State: addressState,
      Postcode: addressPostcode,
      "Employment status": employmentStatus,
    }
  );

  sendGtmEvent({
    event: "conversionFunnel",
    loanAmount: loanAmount,
    interestRate: "",
    loanTerm: "",
    loanPurpose: loanPurpose,
    maritalStatus: "",
    creditHistory: "",
    stepNumber: "2",
    stepName: "Requested quote",
    quoteStatus: "",
    email,
  });

  sendGaEvent("Quote Started", "Get Estimate - clicked");
};

export const isSecuredPurpose = (
  purpose: string,
  loanPurposeList: Purpose[],
  securedParameters: RateEstimate,
  assetFound?: string,
  assetYear?: string,
  assetAgeEstimate?: string,
  assetSecurity?: string
) => {
  const isSecurable =
    loanPurposeList.find((purposeListItem) => purposeListItem.key === purpose)
      ?.isSecurable === true;
  const isAssetSecured = assetSecurity === "Yes";

  if (assetFound === "Yes" && assetYear) {
    const assetAge = dayjs().year() - +assetYear;
    return (
      assetAge <= securedParameters.securedAssetMaxAge &&
      isSecurable &&
      isAssetSecured
    );
  } else if (assetFound === "No" && assetAgeEstimate) {
    return (
      +assetAgeEstimate <= securedParameters.securedAssetMaxAge &&
      isSecurable &&
      isAssetSecured
    );
  }

  return isSecurable;
};

export const mapToCreateEstimateRequest = (
  submittedFormData: QuoteForm,
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>,
  loanParameters: LoanParametersResponse
): DashAPI.SubmitRateEstimateRequest => {
  const secureable = isSecuredPurpose(
    submittedFormData.loanPurpose,
    loanParameters.loanPurpose,
    loanParameters.rateEstimate,
    submittedFormData.assetFound,
    submittedFormData.assetYear,
    submittedFormData.assetAgeEstimate,
    submittedFormData.assetSecurity
  );
  const assetFound = submittedFormData.assetFound.toLowerCase() === "yes";
  const ownsInvestmentProperty =
    submittedFormData.ownsInvestmentProperty.toLowerCase() === "yes";
  const investmentPropertyMortgaged =
    submittedFormData.investmentPropertyMortgaged.toLowerCase() === "yes";
  let identityObj;
  switch (submittedFormData.identityType1) {
    case "DriversLicence":
      identityObj = {
        driverLicence: {
          number: submittedFormData.driverLicenceNumber.trim(),
          state: submittedFormData.driversLicenceState,
          cardNumber: submittedFormData.driverLicenceCardNumber.trim(),
        },
      };
      break;
    case "MedicareCard":
      identityObj = {
        medicare: {
          number: submittedFormData.medicareNumber.trim(),
          cardColour: submittedFormData.medicareColour,
          expiryDate: submittedFormData.medicareExpiryDateGreen.replace(
            / /g,
            ""
          ),
          referenceNumber: submittedFormData.medicareReferenceNumber,
        },
      };
      break;
    case "Passport":
      identityObj = {
        passport: {
          number: submittedFormData.passportNumber.trim(),
          country: "australia",
        },
      };
      break;
    default:
      identityObj = undefined;
  }

  const streetTypeDescription = commonValues.streetTypes.find((streetType) => {
    return (
      streetType.code === submittedFormData.addressStreetType.toUpperCase()
    );
  })?.description;
  const previousStreetTypeDescription = commonValues.streetTypes.find(
    (streetType) => {
      return (
        streetType.code ===
        submittedFormData.previousAddressStreetType.toUpperCase()
      );
    }
  )?.description;

  const previousEmploymentStartDateMonths =
    +submittedFormData.monthsAtPreviousEmployer +
    +submittedFormData.monthsAtEmployer;
  const previousEmploymentStartDateYears =
    +submittedFormData.yearsAtPreviousEmployer +
    +submittedFormData.yearsAtEmployer;

  const previousEmploymentStartDate = dayjs()
    .subtract(previousEmploymentStartDateYears, "year")
    .subtract(previousEmploymentStartDateMonths, "month")
    .format("MM/YYYY")
    .toString();

  const employmentStartDate = dayjs()
    .subtract(+submittedFormData.yearsAtEmployer, "year")
    .subtract(+submittedFormData.monthsAtEmployer, "month")
    .format("MM/YYYY")
    .toString();

  return {
    loanAmount: +submittedFormData.loanAmount,
    loanPurpose: submittedFormData.loanPurpose,
    applicants: [
      {
        ...(submittedFormData.title && {
          title: submittedFormData.title.toLowerCase(),
        }),
        firstName: submittedFormData.firstName.trim(),
        middleName: submittedFormData.middleName.trim(),
        lastName: submittedFormData.lastName.trim(),
        doB: submittedFormData.dateOfBirth.replace(/ /g, ""),
        maritalStatus: submittedFormData.maritalStatus
          .replace(/ /g, "-")
          .toLowerCase(),
        totalIncomeBeforeTax: +submittedFormData.income,
        employments: [
          {
            employmentStatus: submittedFormData.employmentStatus.toLowerCase(),
            startDate: employmentStartDate,
          },
          ...(submittedFormData.previousEmploymentStatus
            ? [
                {
                  employmentStatus:
                    submittedFormData.previousEmploymentStatus.toLowerCase(),
                  startDate: previousEmploymentStartDate,
                  endDate: employmentStartDate,
                },
              ]
            : []),
        ],
        identities: identityObj,
        contact: {
          mobile: submittedFormData.mobile.replace(/ /g, ""),
          email: submittedFormData.email,
        },

        addresses: [
          {
            unit: submittedFormData.addressUnit,
            streetNumber: submittedFormData.addressStreetNumber,
            streetName: submittedFormData.addressStreetName,
            streetType: streetTypeDescription,
            suburb: submittedFormData.addressSuburb,
            state: submittedFormData.addressState,
            postcode: submittedFormData.addressPostcode,
            isPrimary: true,
            years: +submittedFormData.yearsAtAddress,
            months: +submittedFormData.monthsAtAddress,
            residencyStatus:
              submittedFormData.livingSituationAtAddress.toLowerCase(),
          },
          ...(submittedFormData.previousAddressState
            ? [
                {
                  unit: submittedFormData.previousAddressUnit,
                  streetNumber: submittedFormData.previousAddressStreetNumber,
                  streetName: submittedFormData.previousAddressStreetName,
                  streetType: previousStreetTypeDescription,
                  suburb: submittedFormData.previousAddressSuburb,
                  state: submittedFormData.previousAddressState,
                  postcode: submittedFormData.previousAddressPostcode,
                  isPrimary: false,
                  years: +submittedFormData.yearsAtPreviousAddress,
                  months: +submittedFormData.monthsAtPreviousAddress,
                },
              ]
            : []),
        ],

        ...(secureable && {
          investmentProperty: {
            ownsInvestmentProperty: ownsInvestmentProperty,
            ...(ownsInvestmentProperty && {
              investmentPropertyMortgaged: investmentPropertyMortgaged,
            }),
          },
        }),
      },
    ],

    declarations: {
      meetsEligibility: true,
      privacyConsent: true,
      accessSeekerCreditReportConsent: true,
      electronicCommunicationConsent: true,
    },
    requestSecuredRate: secureable,
    ...(secureable && {
      securedAssetDetails: {
        isKnown: assetFound,
        ...(assetFound
          ? {
              yearOfManufacture: +submittedFormData.assetYear,
              saleType: submittedFormData.assetSeller,
            }
          : { approximateAge: +submittedFormData.assetAgeEstimate }),
      },
    }),
  };
};
