import { Events } from "../../shared/analytics/analytics.consts";
import { quoteAmplitudeHelper } from "../../shared/analytics/analytics.utils";
import { TimeSpentOption } from "../quote-form";
import { timeSpentValues } from "../quote-form.constants";

export const timeSpentMapper = (timeSpent: TimeSpentOption) => {
  const selectedValue = timeSpentValues.find(
    (value) => value.option === timeSpent
  );
  return selectedValue || timeSpentValues[0];
};

export const sendEstimateViewedEvent = (pageName: string) => {
  quoteAmplitudeHelper.sendEvent(`${Events.VIEWED_ESTIMATE} - ${pageName}`);
};
