import {
  FieldTrackingState,
  TimeSpentOption,
  TimeSpentValue,
} from "./quote-form";

export const residencyTypesConst = [
  {
    description: "Renting",
    code: "renter",
  },
  {
    description: "Mortgage",
    code: "buyer",
  },
  {
    description: "Own home outright - No mortgage",
    code: "owner",
  },
  {
    description: "Boarding",
    code: "boarder",
  },
  {
    description: "Living with parents",
    code: "living-with-parents",
  },
  {
    description: "Employer provides accommodation",
    code: "employer",
  },
];

export const investmentPropertyResidencies = [
  "renter",
  "boarder",
  "living-with-parents",
  "employer",
];

export const timeSpentOptions: TimeSpentOption[] = [
  "Less than 6 months",
  "6 months to 1 year",
  "Over 1 year",
  "2 years",
  "3 years",
  "More than 4 years",
];

export const timeSpentValues: TimeSpentValue[] = [
  { option: "Less than 6 months", months: 5, years: 0 },
  { option: "6 months to 1 year", months: 0, years: 1 },
  { option: "Over 1 year", months: 6, years: 1 },
  { option: "2 years", months: 0, years: 2 },
  { option: "3 years", months: 0, years: 3 },
  { option: "More than 4 years", months: 0, years: 4 },
];

export const initialFieldTrackingState: FieldTrackingState = {
  loanPurpose: false,
  assetFound: null,
  assetYear: null,
  assetSeller: null,
  assetAgeEstimate: null,
  loanAmount: false,
  title: false,
  firstName: false,
  middleName: false,
  lastName: false,
  dateOfBirth: false,
  maritalStatus: false,
  driverLicenceNumber: false,
  driversLicenceState: false,
  driverLicenceCardNumber: false,
  emailAddress: false,
  mobileNumber: false,
  livingSituation: false,
  livingSituationAtPreviousAddress: null,
  residentialAddress: false,
  previousResidentialAddress: null,
  unitNumber: null,
  previousUnitNumber: null,
  streetNumber: null,
  previousStreetNumber: null,
  streetName: null,
  previousStreetName: null,
  streetType: null,
  previousStreetType: null,
  townSuburb: null,
  previousTownSuburb: null,
  state: null,
  previousState: null,
  postcode: null,
  previousPostcode: null,
  moveInDate: false,
  previousMoveInDate: null,
  employmentStatus: false,
  previousEmploymentStatus: null,
  yearsAtEmployer: false,
  previousYearsAtEmployer: null,
  monthsAtEmployer: false,
  previousMonthsAtEmployer: null,
  totalYearlyIncomeBeforeTax: false,
  termsAndConditions: false,
};

export const quoteFormDefaults = {
  loanAmount: "",
  loanPurpose: "",
  assetFound: "",
  assetYear: "",
  assetSeller: "",
  assetAgeEstimate: "",
  assetSecurity: "Yes",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  maritalStatus: "",
  numberOfFinancialDependents: "",
  identityType1: "",
  driverLicenceNumber: "",
  driversLicenceState: "",
  driverLicenceCardNumber: "",
  medicareNumber: "",
  medicareColour: "",
  medicareExpiryDateGreen: "",
  medicareExpiryDate: "",
  medicareReferenceNumber: "",
  passportNumber: "",
  email: "",
  mobile: "",
  homePhone: "",
  workPhone: "",
  address: "",
  addressUnit: "",
  addressStreetNumber: "",
  addressStreetName: "",
  addressStreetType: "",
  addressSuburb: "",
  addressState: "",
  addressPostcode: "",
  yearsAtAddress: "",
  monthsAtAddress: "",
  livingSituationAtAddress: "",
  previousAddress: "",
  previousAddressUnit: "",
  previousAddressStreetNumber: "",
  previousAddressStreetName: "",
  previousAddressStreetType: "",
  previousAddressSuburb: "",
  previousAddressState: "",
  previousAddressPostcode: "",
  yearsAtPreviousAddress: "",
  monthsAtPreviousAddress: "",
  livingSituationAtPreviousAddress: "",
  employmentStatus: "",
  yearsAtEmployer: "",
  monthsAtEmployer: "",
  previousEmploymentStatus: "",
  yearsAtPreviousEmployer: "",
  monthsAtPreviousEmployer: "",
  income: "",
  termsAndConditionsAgreement: [] as string[],
  ownsInvestmentProperty: "",
  investmentPropertyMortgaged: "",
  movedIn: "",
  movedInPrevious: "",
};
