import { Router } from "@gatsbyjs/reach-router";
import { isBrowserMobile, useForm } from "@wisr/common";
import { Button, Modal } from "@wisr/react-ui";
import { navigate, withPrefix } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";

import { SupportModal } from "../../application/support-modal/support-modal";
import { ApplicationLayout } from "../../layout/application/application-layout.component";
import { fetchRateEstimateTerms } from "../../redux/rate-estimate-terms/rate-estimate-terms.thunk";
import { Events, LoanStatus } from "../../shared/analytics/analytics.consts";
import {
  amplitudeHelper,
  quoteAmplitudeHelper,
} from "../../shared/analytics/analytics.utils";
import { isDebugMode } from "../../shared/config.utils";
import { getPathFromLocation } from "../../shared/gatsby.utils";
import { BRAND_WEBSITE } from "../../shared/url.consts";
import { GatsbyPageLocation } from "../../types/gatsby";
import { QuoteForm, QuoteFormPageQuery } from "../quote-form";
import {
  getErrorsFromState,
  isSecuredPurpose,
  mapToCreateEstimateRequest,
  sendQuoteSubmitAnalytics,
} from "../quote-form.utils";
import { AssetSecurity } from "./form-pages/asset-security/asset-security";
import { CarAge } from "./form-pages/car-age/car-age";
import { CarFound } from "./form-pages/car-found/car-found";
import { CarManufactureYear } from "./form-pages/car-manufacture-year/car-manufacture-year";
import { CarSellerType } from "./form-pages/car-seller-type/car-seller-type";
import { CurrentAddress } from "./form-pages/current-address/current-address";
import { CurrentEmploymentLength } from "./form-pages/current-employment-length/current-employment-length";
import { CurrentEmploymentStatus } from "./form-pages/current-employment-status/current-employment-status";
import { CurrentLivingSituation } from "./form-pages/current-living-situation/current-living-situation";
import { CurrentMovedIn } from "./form-pages/current-moved-in/current-moved-in";
import { Income } from "./form-pages/income/income";
import { InvestmentProperty } from "./form-pages/investment-property/investment-property";
import { InvestmentPropertyMortgaged } from "./form-pages/investment-property-mortgaged/investment-property-mortgaged";
import { LoanAmount } from "./form-pages/loan-amount/loan-amount";
import { LoanPurpose } from "./form-pages/loan-purpose/loan-purpose";
import { NoMatch } from "./form-pages/no-match/no-match";
import { PreviousAddress } from "./form-pages/previous-address/previous-address";
import { PreviousEmploymentLength } from "./form-pages/previous-employment-length/previous-employment-length";
import { PreviousEmploymentStatus } from "./form-pages/previous-employment-status/previous-employment-status";
import { PreviousMovedIn } from "./form-pages/previous-moved-in/previous-moved-in";
import { RelationshipStatus } from "./form-pages/relationship-status/relationship-status";
import { YourID } from "./form-pages/your-id/your-id";
import style from "./multi-page-quote-form.scss";
import { ReviewEstimate } from "./form-pages/review-estimate/review-estimate";
import { quoteFormSetAction } from "../quote-form.actions";
import { LoanParametersResponse } from "../../types/loan-parameters";
import { submitRateEstimate } from "../../redux/rate-estimate/rate-estimate.thunk";
import { useGetRateEstimateState } from "../../redux/rate-estimate/rate-estimate.hooks";
import { FetchStatus } from "../../shared/redux.consts";
import { QuoteSubmitted } from "./form-pages/quote-submitted/quote-submitted";

interface PropTypes {
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
  quoteFormInitial: QuoteForm;
  loanParametersResponse: LoanParametersResponse;
  location?: GatsbyPageLocation;
}

export const MultiPageQuoteForm: React.FC<PropTypes> = (props) => {
  const { commonValues, quoteFormInitial, loanParametersResponse, location } =
    props;

  const { fieldAdapter, submitAdapter, formData, setValue, formState } =
    useForm(quoteFormInitial);

  const pathname = getPathFromLocation(location);
  const routerStyle = {
    display: "flex",
    flexGrow: 1,
    paddingBottom: "40px",
    ...(!isBrowserMobile() && {
      alignItems: "flex-start",
    }),
  };
  const isSecurable = isSecuredPurpose(
    formData.loanPurpose,
    loanParametersResponse.loanPurpose,
    loanParametersResponse.rateEstimate,
    formData.assetFound,
    formData.assetYear,
    formData.assetAgeEstimate,
    formData.assetSecurity
  );

  const dispatch = useDispatch();
  const rateEstimateState = useGetRateEstimateState();
  const submitting = rateEstimateState.postStatus === FetchStatus.LOADING;
  const submissionErrors =
    rateEstimateState.postStatus === FetchStatus.FAILED
      ? rateEstimateState.error
      : "";

  const [errorModal, setErrorModal] = React.useState(false);
  const [supportModal, setSupportModal] = React.useState(false);
  const [formSubmissionErrors, setFormErrors] = React.useState<string[]>([]);

  const onSubmit = submitAdapter((result, _submittedFormData, state) => {
    const formDataToSubmit = formData;
    if (result) {
      const createEstimateRequest = mapToCreateEstimateRequest(
        formDataToSubmit,
        commonValues,
        loanParametersResponse
      );

      dispatch(quoteFormSetAction(formDataToSubmit));
      dispatch(submitRateEstimate(createEstimateRequest));
      sendQuoteSubmitAnalytics(formDataToSubmit);
    } else {
      const errorsInFormState = getErrorsFromState(state);
      quoteAmplitudeHelper.sendEvent(Events.USER_ERROR, {
        Type: "Incomplete Quote Form",
        Page: window.location.href,
        Info: errorsInFormState,
      });
      setFormErrors(Object.values(errorsInFormState).map((error) => error[0]));
      console.log(Object.entries(errorsInFormState));
    }

    return Promise.resolve();
  });

  React.useEffect(() => {
    amplitudeHelper.sendEvent(Events.VIEWED_SECOND_LOAN_ESTIMATE);
    quoteAmplitudeHelper.sendEvent(
      Events.VIEWED_ESTIMATE,
      {
        "Loan application status": LoanStatus.QUOTE_STARTED,
      },
      {
        "Loan application status": LoanStatus.QUOTE_STARTED,
        "RE Form Split Tests": "RE_Form_v3",
      }
    );
  }, []);

  React.useEffect(() => {
    dispatch(fetchRateEstimateTerms());
  }, [dispatch]);

  React.useEffect(() => {
    if (submissionErrors || formSubmissionErrors.length > 0) {
      setErrorModal(true);
    }
  }, [formSubmissionErrors, submissionErrors]);

  React.useEffect(() => {
    if (formData && !isDebugMode()) {
      if (formData.loanPurpose === "" && pathname !== "quote") {
        navigate("/quote");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      rateEstimateState.postStatus === FetchStatus.SUCCESS &&
      rateEstimateState.wisrApplicationId
    ) {
      navigate(`/quote/submitted?appid=${rateEstimateState.wisrApplicationId}`);
    }
  }, [rateEstimateState.postStatus, rateEstimateState.wisrApplicationId]);

  if (pathname === "submitted") {
    return <QuoteSubmitted />;
  }

  return (
    <ApplicationLayout
      layoutType="multi-page-form"
      setSupportModal={setSupportModal}
      location={location}
    >
      <Router basepath={withPrefix("/quote")} style={routerStyle}>
        <LoanPurpose
          path="/loan-purpose"
          fieldAdapter={fieldAdapter}
          formData={formData}
          default={true}
          loanParameters={loanParametersResponse}
        />
        <LoanAmount
          path="/loan-amount"
          fieldAdapter={fieldAdapter}
          formData={formData}
          setValue={setValue}
          formState={formState}
          isSecurable={isSecurable}
          loanLimits={loanParametersResponse.rateEstimate.limits}
        />
        <CarFound path="/car-found" setValue={setValue} />
        <CarManufactureYear
          path="/car-manufacture-year"
          fieldAdapter={fieldAdapter}
          formData={formData}
          setValue={setValue}
          formState={formState}
        />
        <CarAge
          path="/car-age"
          setValue={setValue}
          commonValues={commonValues}
        />
        <CarSellerType
          path="/car-seller-type"
          formData={formData}
          setValue={setValue}
          commonValues={commonValues}
        />
        <AssetSecurity
          path="/asset-security"
          fieldAdapter={fieldAdapter}
          formData={formData}
          commonValues={commonValues}
        />
        <RelationshipStatus path="/relationship-status" setValue={setValue} />
        <CurrentLivingSituation
          path="/current-living-situation"
          setValue={setValue}
          isSecurable={isSecurable}
        />
        <InvestmentProperty path="/investment-property" setValue={setValue} />
        <InvestmentPropertyMortgaged
          path="/mortgaged-investment-property"
          setValue={setValue}
        />
        <CurrentAddress
          path="/current-address"
          fieldAdapter={fieldAdapter}
          formData={formData}
          setValue={setValue}
          commonValues={commonValues}
        />
        <CurrentMovedIn
          path="/current-moved-in"
          fieldAdapter={fieldAdapter}
          formData={formData}
          setValue={setValue}
          commonValues={commonValues}
        />
        <PreviousAddress
          path="/previous-address"
          fieldAdapter={fieldAdapter}
          formData={formData}
          setValue={setValue}
          commonValues={commonValues}
        />
        <PreviousMovedIn path="/previous-moved-in" setValue={setValue} />
        <CurrentEmploymentStatus
          path="/current-employment-status"
          setValue={setValue}
          commonValues={commonValues}
        />
        <CurrentEmploymentLength
          path="/current-employment-length"
          formData={formData}
          setValue={setValue}
        />
        <PreviousEmploymentStatus
          path="/previous-employment-status"
          setValue={setValue}
          commonValues={commonValues}
        />
        <PreviousEmploymentLength
          path="/previous-employment-length"
          setValue={setValue}
        />
        <Income
          path="/income"
          fieldAdapter={fieldAdapter}
          formData={formData}
          commonValues={commonValues}
          formState={formState}
        />
        <NoMatch
          path="/no-match"
          fieldAdapter={fieldAdapter}
          setValue={setValue}
          formData={formData}
          commonValues={commonValues}
          onSubmit={onSubmit}
        />
        <NoMatch
          path="/no-match-again"
          fieldAdapter={fieldAdapter}
          setValue={setValue}
          formData={formData}
          commonValues={commonValues}
          multipleSearchAttempts={true}
          onSubmit={onSubmit}
        />
        <YourID
          path="/your-id"
          fieldAdapter={fieldAdapter}
          formData={formData}
          formState={formState}
          onSubmit={onSubmit}
        />
        <ReviewEstimate
          path="/review"
          fieldAdapter={fieldAdapter}
          formData={formData}
          formState={formState}
          onSubmit={onSubmit}
          submitting={submitting}
        />
      </Router>
      <Modal
        titleText={"Form errors"}
        open={errorModal}
        onClose={
          // istanbul ignore next
          () => setErrorModal(false)
        }
      >
        <div className="error-modal">
          <style jsx>{style}</style>
          {typeof submissionErrors === "string" && (
            <p className="error-text">{submissionErrors}</p>
          )}
          {formSubmissionErrors.length > 0 && (
            <ul>
              {formSubmissionErrors.map((error, i) => {
                return <li key={i}>{error}</li>;
              })}
            </ul>
          )}
          {submissionErrors && Array.isArray(submissionErrors) && (
            <ul>
              {submissionErrors.map((error, i) => {
                return <li key={i}>{error}</li>;
              })}
            </ul>
          )}
          <p>If this issue persists please contact us.</p>
          <div className="button-wrapper">
            <Button theme="navy" fullWidth={true}>
              <a
                href={`${BRAND_WEBSITE}/contact-us?subject=Loans`}
                rel="noreferrer"
                target="_blank"
              >
                Contact us
              </a>
            </Button>
          </div>
        </div>
      </Modal>
      <SupportModal
        supportModal={supportModal}
        setSupportModal={setSupportModal}
      />
    </ApplicationLayout>
  );
};
