import { isBrowser } from "@wisr/common";
import { Icon } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ReviewField } from "../review-fields/review-fields";

interface GetEditLinkProps {
  data: ReviewField;
  sectionKey: string;
}

export const EditLink = ({ data, sectionKey }: GetEditLinkProps) => {
  if (!data.editable || !data.editPath) {
    return null;
  }

  const handleEditLinkClick = () => {
    const touchedSections =
      isBrowser() && window.sessionStorage.getItem("touchedSections");

    const handleNavigate = () => {
      navigate(`/quote/${data.editPath}`);
    };

    if (touchedSections) {
      const touchedSectionsArr = JSON.parse(touchedSections);
      const touchedSectionsSet = new Set([...touchedSectionsArr, sectionKey]);
      window.sessionStorage.setItem(
        "touchedSections",
        JSON.stringify(Array.from(touchedSectionsSet))
      );
      handleNavigate();
    } else {
      isBrowser() &&
        window.sessionStorage.setItem(
          "touchedSections",
          JSON.stringify([sectionKey])
        );
      handleNavigate();
    }
  };

  return (
    <div onClick={handleEditLinkClick} className="update-field-icon">
      <Icon name="action-edit-outline" size="sm" legacy={true} />
    </div>
  );
};
