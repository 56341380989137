export const RateEstimateStatus = {
  PENDING: "RateEstimatePending",
  SUCCESSFUL: "RateEstimateSuccessful",
  REDUCED: "RateEstimateReduced",
  DECLINED: "RateEstimateDeclined",
  NO_RECORD: "RateEstimateNoRecord",
  EXPIRED: "RateEstimateExpired",
  INVALID: "RateEstimateInvalid",
  EQUIFAX_ERROR: "RateEstimateEquifaxError",
  ERROR: "RateEstimateError",
  ACCOUNT_SUSPENDED: "RateEstimateAccountSuspended",
};
