import React from "react";

export const defaultTermsSection = (
  <>
    <p className="bold">
      Before we get your personalised rate, please read the below carefully.
    </p>
    <p className="bold">By getting an estimate:</p>
    <ul>
      <li>
        I have read and agreed to the terms set out in the{" "}
        <a
          href="https://wisrau.blob.core.windows.net/public/PrivacyConsent-20181024.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Consent Form
        </a>{" "}
        and to the disclosure of my personal information to third parties,
        including to credit reporting bodies for customer identification, or for
        the purpose of direct marketing to a third party, which may include a
        party who may assist me with a loan if my application is unsuccessful
        for which the Wisr group may receive a commission.
      </li>
      <li>
        I authorise Wisr Services Pty Ltd ACN 616 806 949 to contact a credit
        reporting body to access my credit report for the purpose of providing a
        rate estimate.
      </li>
      <li>
        I confirm I am currently residing in Australia. I confirm I am either:
        <ul>
          <li>an Australian citizen; or</li>
          <li>an Australian permanent resident.</li>
        </ul>
      </li>
      <li>
        I consent to the receipt of notices and other documents electronically
        as per the{" "}
        <a id="communicationConsentLink">
          Electronic Communication Consent Details
        </a>
        .
      </li>
    </ul>
  </>
);

export const defaultCommunicationConsentSection = (
  <div id="communicationConsent">
    <p>
      To: Wisr Finance Pty Ltd (ABN 39 119 503 221, Australian Credit Licence
      458572),
    </p>
    <p>I understand that by ticking this box I have given consent such that:</p>
    <ol>
      <li>
        Wisr may no longer send paper copies of notices and other documents to
        me;
      </li>
      <li>
        I should regularly check my nominated email address for notices and
        other documents;
      </li>
      <li>
        Wisr may send the notices and other documents by email, or provide a
        notice in an email that the documents are displayed on and can be
        retrieved from a website;
      </li>
      <li>
        I may withdraw my consent to the giving of notices and other documents
        by electronic means at any time; and
      </li>
      <li>
        I have the facilities to enable me to print the notices or other
        documents sent to me electronically.
      </li>
    </ol>
  </div>
);
