import { createValidator } from "@wisr/common";
import { CommonType, StreetType } from "../../generated/graphql-types";

export const commonTypeMapper = (res: CommonType) => {
  return {
    label: res.description,
    value: res.code,
  };
};

export function streetMapper(streetType: StreetType) {
  const value =
    streetType.code.charAt(0).toUpperCase() +
    streetType.code.slice(1).toLowerCase();
  return {
    label: `${streetType.description} (${value})`,
    value,
  };
}

export function validatePassportNumber(value: string): boolean {
  return /^[A-Za-z]{1,2}\d{7}$/.test(value);
}

export const passportNumberValidator = createValidator(
  validatePassportNumber,
  "Please enter a valid passport number"
);

export const isValidLengthWithoutDecimals = (value: string, length: number) => {
  const lengthWithoutDecimals = length - 2;
  const splitValue = value.split(".");

  if (
    (splitValue[0] && splitValue[0].length > lengthWithoutDecimals) ||
    (splitValue[1] && splitValue[1].length > 2)
  ) {
    return false;
  } else {
    return true;
  }
};
