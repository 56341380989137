import { capitalise } from "@wisr/common";
import { QuoteForm, QuoteFormPageQuery } from "../../quote/quote-form";
import { RateEstimatePrefillResponse } from "../../types/rate-estimate-prefill";
import {
  addMobileSpaces,
  addSlashSpaces,
  getAddress,
  mapToAddressUpdated,
  valueToString,
  valueToYesNo,
} from "../loan-application/loan-application.utils";

const getEmployment = (
  employments: DashAPI.Employment[] | null | undefined,
  isCurrentEmployment: boolean
) => {
  if (employments && employments.length > 0) {
    return (
      employments.find(
        (employment) => employment.isPrimary === isCurrentEmployment
      ) ?? {}
    );
  }

  return {};
};

export const mapToQuoteForm = (
  response?: RateEstimatePrefillResponse,
  commonValues?: NonNullable<QuoteFormPageQuery["commonValues"]> | null
): QuoteForm | null => {
  if (
    response &&
    response.applicants &&
    response.applicants[0] &&
    commonValues
  ) {
    const applicant = response.applicants[0];
    const primaryAddress = getAddress(applicant.addresses, true);
    const secondaryAddress = getAddress(applicant.addresses, false);
    const currentEmployment = getEmployment(applicant.employments, true);
    const previousEmployment = getEmployment(applicant.employments, false);

    const streetTypeCode = commonValues.streetTypes.find((streetType) => {
      return (
        streetType.description.toLowerCase() ===
        valueToString(primaryAddress.streetType).toLowerCase()
      );
    })?.code;
    const previousStreetTypeCode = commonValues.streetTypes.find(
      (streetType) => {
        return (
          streetType.description.toLowerCase() ===
          valueToString(secondaryAddress.streetType).toLowerCase()
        );
      }
    )?.code;

    return {
      loanAmount: "",
      loanPurpose: "",
      assetFound: "",
      assetYear: "",
      assetSeller: "",
      assetAgeEstimate: "",
      assetSecurity: "Yes",

      title: valueToString(applicant.title),
      firstName: valueToString(applicant.firstName),
      middleName: valueToString(applicant.middleName),
      lastName: valueToString(applicant.lastName),
      dateOfBirth: addSlashSpaces(valueToString(applicant.doB)),
      maritalStatus: valueToString(applicant.maritalStatus),
      numberOfFinancialDependents: "",

      identityType1: "",
      driverLicenceNumber: "",
      driversLicenceState: "",
      driverLicenceCardNumber: "",
      medicareNumber: "",
      medicareColour: "",
      medicareExpiryDateGreen: "",
      medicareExpiryDate: "",
      medicareReferenceNumber: "",
      passportNumber: "",

      email: valueToString(applicant.contact?.email),
      mobile: addMobileSpaces(valueToString(applicant.contact?.mobile)),
      homePhone: "",
      workPhone: "",

      address: mapToAddressUpdated(primaryAddress),
      addressUnit: valueToString(primaryAddress.unit),
      addressStreetNumber: valueToString(primaryAddress.streetNumber),
      addressStreetName: valueToString(primaryAddress.streetName),
      addressStreetType: capitalise(
        valueToString(streetTypeCode).toLowerCase()
      ),
      addressSuburb: valueToString(primaryAddress.suburb),
      addressState: valueToString(primaryAddress.state).toUpperCase(),
      addressPostcode: valueToString(primaryAddress.postcode),
      yearsAtAddress: valueToString(primaryAddress.years),
      monthsAtAddress: valueToString(primaryAddress.months),
      livingSituationAtAddress: valueToString(primaryAddress.residencyStatus),

      previousAddress: mapToAddressUpdated(secondaryAddress),
      previousAddressUnit: valueToString(secondaryAddress.unit),
      previousAddressStreetNumber: valueToString(secondaryAddress.streetNumber),
      previousAddressStreetName: valueToString(secondaryAddress.streetName),
      previousAddressStreetType: capitalise(
        valueToString(previousStreetTypeCode).toLowerCase()
      ),
      previousAddressSuburb: valueToString(secondaryAddress.suburb),
      previousAddressState: valueToString(secondaryAddress.state).toUpperCase(),
      previousAddressPostcode: valueToString(secondaryAddress.postcode),
      yearsAtPreviousAddress: valueToString(secondaryAddress.years),
      monthsAtPreviousAddress: valueToString(secondaryAddress.months),
      livingSituationAtPreviousAddress: valueToString(
        secondaryAddress.residencyStatus
      ),

      employmentStatus: valueToString(currentEmployment.employmentStatus),
      yearsAtEmployer: valueToString(currentEmployment.years),
      monthsAtEmployer: valueToString(currentEmployment.months),

      previousEmploymentStatus: valueToString(
        previousEmployment.employmentStatus
      ),
      yearsAtPreviousEmployer: valueToString(previousEmployment.years),
      monthsAtPreviousEmployer: valueToString(previousEmployment.months),

      income: valueToString(applicant.totalIncomeBeforeTax),
      termsAndConditionsAgreement: [] as string[],
      ownsInvestmentProperty: valueToYesNo(
        applicant.investmentProperty?.ownsInvestmentProperty
      ),
      investmentPropertyMortgaged: valueToYesNo(
        applicant.investmentProperty?.investmentPropertyMortgaged
      ),

      movedIn: "",
      movedInPrevious: "",
    };
  }

  return null;
};
