import React from "react";

export const getAssetSecurityQuestionCopy = (
  rateEstimateSecureQuestion: string,
  loanPurpose: string
): string => {
  if (rateEstimateSecureQuestion === "optionA") {
    return `Do you want to provide the ${loanPurpose.toLowerCase()} you purchase as security for the loan?`;
  } else {
    return "What kind of loan are you after?";
  }
};

export const getAssetSecurityOptions = (rateEstimateSecureQuestion: string) => {
  if (rateEstimateSecureQuestion === "optionA") {
    return ["Yes", "No"];
  } else {
    return [
      { label: "Secured", value: "Yes" },
      { label: "Unsecured", value: "No" },
    ];
  }
};

export const getAssetSecurityCalloutCopy = (
  assetSecured: string,
  rateEstimateSecureQuestion: string,
  monthlyAccountFee: number
): JSX.Element => {
  const isOptionA = rateEstimateSecureQuestion === "optionA";
  if (assetSecured === "Yes") {
    return (
      <>
        {isOptionA ? (
          <>
            <strong>This will be a &lsquo;secured&rsquo; loan</strong>
            <p>
              This loan allows you to use your vehicle as security for your loan
            </p>
          </>
        ) : (
          <>
            <strong>Secured loan</strong>
            <p>
              This loan allows you to use your vehicle as security for your loan
            </p>
          </>
        )}
        <ul>
          <li>
            This may qualify you for a lower interest rate, but allows Wisr to
            repossess the vehicle if you stop making your loan repayments
          </li>
          <li>
            You&apos;ll need to take out and maintain insurance over the vehicle
            over the term of your loan
          </li>
          <li>
            An account fee of ${monthlyAccountFee} per month applies to your
            secured loan
          </li>
        </ul>
      </>
    );
  } else {
    return (
      <>
        {isOptionA ? (
          <>
            <strong>This will be an &lsquo;unsecured&rsquo; loan</strong>
            <p>This loan does not use any asset as security</p>
          </>
        ) : (
          <>
            <strong>Unsecured loan</strong>
            <p>This loan does not use any asset as security</p>
          </>
        )}
        <ul>
          <li>
            You may have to pay a higher interest rate compared to a secured
            loan
          </li>
          <li>There is no monthly account fee</li>
        </ul>
      </>
    );
  }
};
