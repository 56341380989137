import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import React, { FormEventHandler } from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import padlockImg from "../../../../assets/images/padlock.svg";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import formStyle from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import { IdentificationFields } from "./fields/identification-fields";
import componentStyle from "./your-id.scss";
import { disabledSubmit } from "./your-id.utils";

interface YourIdPropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  formState: UseFormTools<QuoteForm>["formState"];
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const YourID = ({
  fieldAdapter,
  formData,
  formState,
  onSubmit,
}: YourIdPropTypes) => {
  const identityIndex = `identityType${1}`;
  const formDataIdentityIndex =
    formData[identityIndex as keyof UseFormTools<QuoteForm>["formData"]];
  const isDisabled = disabledSubmit({
    formDataIdentityIndex,
    formData,
    formState,
  });

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.IDENTIFICATION);
  }, []);

  return (
    <form className="multi-page no-match-screen" noValidate onSubmit={onSubmit}>
      <style jsx>{formStyle}</style>
      <style jsx>{componentStyle}</style>
      <div className="form-contents">
        <FormPageHeading
          title="Lastly your ID"
          description="This will help us find your credit profile data so we can get your rate estimate."
        />
        <div className="identification-fields-wrapper">
          <IdentificationFields
            index={1}
            disabled={false}
            formData={formData}
            fieldAdapter={fieldAdapter}
            identityIndex={identityIndex}
            formDataIdentityIndex={formDataIdentityIndex}
          />
        </div>

        <div className="security-statement-wrapper">
          <div className="security-statement-icon">
            <img src={padlockImg} alt="" />
          </div>
          <p>We use bank grade security to keep your data safe.</p>
        </div>

        <div className="button-wrapper">
          <Button theme="navy" disabled={isDisabled} fullWidth={true}>
            <button type="submit" disabled={isDisabled}>
              confirm
            </button>
          </Button>
        </div>
      </div>
    </form>
  );
};
