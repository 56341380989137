import { isBrowser, UseFormTools } from "@wisr/common";
import { Accordion } from "@wisr/react-ui";
import React from "react";

import style from "../../../multi-page-quote-form.scss";
import reviewStyle from "../review-estimate.scss";
import { useGetCustomerLoanParametersState } from "../../../../../redux/customer-loan-parameters/customer-loan-parameters.hooks";
import { QuoteForm } from "../../../../quote-form";
import { getReviewFields, ReviewField } from "../review-fields/review-fields";
import { EditLink } from "../edit-link/edit-link";
interface ReviewAccordionProps {
  formData: UseFormTools<QuoteForm>["formData"];
}
export const ReviewAccordion = ({ formData }: ReviewAccordionProps) => {
  const loanParametersState = useGetCustomerLoanParametersState();
  const reviewData: { [char: string]: ReviewField[] | [] } = getReviewFields(
    formData,
    loanParametersState.loanParameters.loanPurpose
  );
  const sessionStorageItems =
    isBrowser() && window.sessionStorage.getItem("touchedSections");
  const [touchedSections, setTouchedSections] = React.useState<string[]>(
    sessionStorageItems ? JSON.parse(sessionStorageItems) : ["Your Loan"]
  );

  React.useEffect(() => {
    sessionStorageItems && setTouchedSections(JSON.parse(sessionStorageItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx>{style}</style>
      <style jsx>{reviewStyle}</style>

      {touchedSections &&
        Object.keys(reviewData).map((sectionKey) => {
          return (
            <Accordion title={sectionKey} startOpen={true} key={sectionKey}>
              {reviewData[sectionKey].map((data, i) => (
                <div className="formdata-review-field" key={i}>
                  {data.label ? (
                    <>
                      <span className="label">{data.label}</span>
                      <div>
                        <span className="value">{data.value}</span>
                        <EditLink data={data} sectionKey={sectionKey} />
                      </div>
                    </>
                  ) : (
                    <span className={`value-only item-${i}`}>{data.value}</span>
                  )}
                </div>
              ))}
            </Accordion>
          );
        })}
    </>
  );
};
