import { UseFormTools } from "@wisr/common";

import { QuoteForm } from "../../../../quote-form";
import { userDetailTypes } from "./user-details";

export const showLabel = (type: userDetailTypes) => {
  switch (type) {
    case "name":
      return "Name";

    case "dob":
      return "Date of Birth";

    case "address":
      return "Residential Address";
  }
};

export const showValue = ({
  type,
  formData,
}: {
  type: userDetailTypes;
  formData: UseFormTools<QuoteForm>["formData"];
}) => {
  let name;
  let dob;
  let address;

  const hasManualAddress =
    formData.addressUnit ||
    formData.addressStreetNumber ||
    formData.addressStreetName ||
    formData.addressStreetType ||
    formData.addressSuburb ||
    formData.addressState ||
    formData.addressPostcode;

  const street = [
    formData.addressUnit,
    formData.addressStreetNumber,
    formData.addressStreetName,
    formData.addressStreetType,
  ]
    .filter((element) => !!element)
    .join(" ");

  const suburb = [
    formData.addressSuburb,
    formData.addressState,
    formData.addressPostcode,
  ]
    .filter((element) => !!element)
    .join(" ");

  const manualAddress =
    street && suburb ? street + ", " + suburb : street + suburb;
  switch (type) {
    case "name":
      if (!formData.firstName || !formData.lastName) {
        name = "Not set";
      } else {
        name = `${formData.firstName} ${formData.lastName}`;
      }
      return name;
    case "dob":
      if (!formData.dateOfBirth) {
        dob = "Not set";
      } else {
        dob = formData.dateOfBirth;
      }
      return dob;
    case "address":
      if (formData.address) {
        address = `${formData.address}`;
      } else if (hasManualAddress) {
        address = manualAddress;
      } else {
        address = "Not set";
      }
      return address;
  }
};
