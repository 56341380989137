import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Address, Button } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { commonTypeMapper, streetMapper } from "../../../../shared/form.utils";
import { isLessThan3YearsAt } from "../../../../shared/application/form.utils";
import { QuoteForm, QuoteFormPageQuery } from "../../../quote-form";
import { residencyTypesConst } from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import {
  autocompleteAddress,
  parseAddress,
} from "../../../../forms/credit-profile/credit-profile-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
  commonValues: NonNullable<QuoteFormPageQuery["commonValues"]>;
}

export const PreviousAddress: React.FC<PropTypes> = (props) => {
  const { fieldAdapter, formData, setValue, commonValues } = props;

  const states = commonValues.states.map(commonTypeMapper);
  const residencyTypes = residencyTypesConst.map(commonTypeMapper);
  const streetTypes = [
    ...commonValues.streetTypes
      .filter((streetType) => streetType.common)
      .map(streetMapper),
    "",
    { label: "--------------", value: "" },
    "",
    ...commonValues.streetTypes.map(streetMapper),
  ];
  const lessThan3YearsAtAddress = isLessThan3YearsAt(
    formData.yearsAtAddress,
    formData.monthsAtAddress
  );

  const isDisabled = !(
    formData.previousAddressPostcode &&
    formData.previousAddressState &&
    formData.previousAddressStreetName &&
    formData.previousAddressStreetNumber &&
    formData.previousAddressSuburb
  );

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.PREVIOUS_ADDRESS);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <div className="form-contents">
        <FormPageHeading
          title="What was your previous address?"
          description="Start typing your address below. If it does not appear, please enter it manually."
        />

        <Address
          {...{
            search: autocompleteAddress,
            validate: parseAddress,
            fieldAdapter,
            formData,
            setValue,
            condition: lessThan3YearsAtAddress,
            states,
            residencyTypes,
            streetTypes,
            showMovedIn: false,
            showLivingSituation: false,
            optionalStreetType: true,
            mappings: {
              search: "previousAddress",
              unitNumber: "previousAddressUnit",
              number: "previousAddressStreetNumber",
              street: "previousAddressStreetName",
              streetType: "previousAddressStreetType",
              suburb: "previousAddressSuburb",
              state: "previousAddressState",
              postcode: "previousAddressPostcode",
              monthsAt: "monthsAtPreviousAddress",
              yearsAt: "yearsAtPreviousAddress",
              livingSituationAt: "livingSituationAtPreviousAddress",
              movedIn: "movedInPrevious",
            },
          }}
        />
      </div>

      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => navigate("/quote/previous-moved-in")}
            disabled={isDisabled}
          >
            next
          </button>
        </Button>
      </div>
    </div>
  );
};
