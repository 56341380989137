import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import { maritalStatusOptions } from "../../multi-page-quote-form.constants";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
}

export const RelationshipStatus: React.FC<PropTypes> = ({ setValue }) => {
  const [fieldValue, setFieldValue] = React.useState("");

  React.useEffect(() => {
    if (fieldValue !== "") {
      setValue("maritalStatus", fieldValue);
      setFieldValue("");
      navigate("/quote/review");
    }
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.RELATIONSHIP_STATUS);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>

      <FormPageHeading title="What's your relationship status?" />

      <Field
        type="radio-list"
        label=""
        options={maritalStatusOptions}
        onChange={(e) => setFieldValue(e.currentTarget.value)}
      />
    </div>
  );
};
