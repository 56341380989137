import React from "react";

export const CaravanIcon = () => {
  return (
    <svg
      width="400"
      height="400"
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="27" y="110" width="173" height="71" className="icon-fill" />
      <rect x="209" y="110" width="79" height="149" className="icon-fill" />
      <circle cx="113.5" cy="273.5" r="30.5" className="icon-fill" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.048 242.837C277.048 248.27 272.642 252.671 267.213 252.671H227.866C222.427 252.671 218.026 248.27 218.026 242.837V134.642C218.026 129.214 222.427 124.808 227.866 124.808H267.213C272.642 124.808 277.048 129.214 277.048 134.642V242.837ZM186.884 159.236C186.884 164.665 182.483 169.07 177.05 169.07H137.702C132.268 169.07 127.868 164.665 127.868 159.236V134.642C127.868 129.214 132.268 124.808 137.702 124.808H177.05C182.483 124.808 186.884 129.214 186.884 134.642V159.236ZM98.3644 159.236C98.3644 164.665 93.9636 169.07 88.5347 169.07H49.187C43.7533 169.07 39.3477 164.665 39.3477 159.236V134.642C39.3477 129.214 43.7533 124.808 49.187 124.808H88.5347C93.9636 124.808 98.3644 129.214 98.3644 134.642V159.236ZM328.868 218.247V137.102C328.868 107.224 304.644 83 274.775 83H54.1018C24.2236 83 0 107.224 0 137.102V218.247C0 248.126 24.2236 272.344 54.1018 272.344H59.0167C59.0167 242.514 83.2883 218.247 113.114 218.247C142.939 218.247 167.206 242.514 167.206 272.344H274.766C304.644 272.344 328.868 248.126 328.868 218.247ZM113.115 296.932C99.533 296.932 88.5262 285.926 88.5262 272.344C88.5262 258.767 99.533 247.76 113.115 247.76C126.697 247.76 137.704 258.767 137.704 272.344C137.704 285.926 126.702 296.932 113.115 296.932ZM113.115 228.081C88.6709 228.081 68.8529 247.904 68.8529 272.344C68.8529 296.788 88.6709 316.606 113.115 316.606C137.555 316.606 157.378 296.798 157.378 272.344C157.378 247.894 137.565 228.081 113.115 228.081ZM383.608 236.282C383.608 231.751 387.273 228.081 391.799 228.081C396.32 228.081 400 231.751 400 236.282V267.419C400 270.138 397.785 272.343 395.076 272.343H303.942C310.855 268.605 316.952 263.575 321.953 257.589H383.608V236.282Z"
        fill="#15384F"
      />
    </svg>
  );
};
