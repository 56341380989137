import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm, TimeSpentOption } from "../../../quote-form";
import { timeSpentOptions } from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import {
  sendEstimateViewedEvent,
  timeSpentMapper,
} from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
}

export const CurrentEmploymentLength: React.FC<PropTypes> = ({
  formData,
  setValue,
}) => {
  const [fieldValue, setFieldValue] = React.useState<TimeSpentOption>();

  React.useEffect(() => {
    if (fieldValue !== undefined) {
      const selectedValue = timeSpentMapper(fieldValue);
      setValue("monthsAtEmployer", `${selectedValue.months}`);
      setValue("yearsAtEmployer", `${selectedValue.years}`);
      setFieldValue(undefined);

      if (
        selectedValue.years === 0 &&
        selectedValue.months < 6 &&
        !formData.previousEmploymentStatus
      ) {
        navigate("/quote/previous-employment-status");
      } else {
        navigate("/quote/review");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.CURRENT_EMPLOYMENT_LENGTH);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="How long have you worked with your current employer?" />

      <Field
        type="radio-list"
        label=""
        options={timeSpentOptions}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value as TimeSpentOption);
        }}
      />
    </div>
  );
};
