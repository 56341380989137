import { RouteComponentProps } from "@reach/router";
import {
  amountAllowedValidator,
  combineValidators,
  toCurrency,
  UseFormTools,
  wholeDollarAmountValidator,
} from "@wisr/common";
import { Button, Field, Icon, Slider } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardQuoteCopies } from "../../../../context-card/context-card.constants";
import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm } from "../../../quote-form";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import { CONSOLIDATION_PURPOSES } from "../../multi-page-quote-form.constants";
import style from "../../multi-page-quote-form.scss";
import { sendEstimateViewedEvent } from "../../multi-page-quote-form.utils";
import { navigateToInvestmentCheck } from "../current-living-situation/current-living-situation.utils";
import { Limits } from "../../../../types/loan-parameters";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  formData: UseFormTools<QuoteForm>["formData"];
  setValue: UseFormTools<QuoteForm>["setValue"];
  formState: UseFormTools<QuoteForm>["formState"];
  isSecurable: boolean;
  loanLimits: Limits;
}

export const LoanAmount: React.FC<PropTypes> = (props) => {
  const {
    fieldAdapter,
    formData,
    setValue,
    formState,
    isSecurable,
    loanLimits,
  } = props;
  const loanAmountMin = isSecurable
    ? loanLimits.secure.min
    : loanLimits.unsecure.min;
  const loanAmountMax = isSecurable
    ? loanLimits.secure.max
    : loanLimits.unsecure.max;
  const [manualField, setManualField] = React.useState(false);
  const isDisabled =
    !formData.loanAmount || formState.loanAmount.errors.length !== 0;

  const getContextCardCopy = () => {
    if (CONSOLIDATION_PURPOSES.includes(formData.loanPurpose)) {
      return ContextCardQuoteCopies.DEBT_SMARTER;
    } else if (formData.loanPurpose === "home-improvements") {
      return ContextCardQuoteCopies.FRESH_COAT;
    } else {
      return ContextCardQuoteCopies.PAY_FASTER;
    }
  };

  const shouldShowInvestmentStep = navigateToInvestmentCheck({
    fieldValue: formData.livingSituationAtAddress,
    isSecurable,
  });

  const nextPage = () => {
    if (shouldShowInvestmentStep) {
      navigate("/quote/investment-property");
    } else {
      navigate("/quote/review");
    }
  };

  React.useEffect(() => {
    if (+formData.loanAmount < loanAmountMin) {
      setValue("loanAmount", loanAmountMin.toString());
    } else if (+formData.loanAmount > loanAmountMax) {
      setValue("loanAmount", loanAmountMax.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.AMOUNT);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <div className="form-contents">
        <FormPageHeading title="How much would you like to borrow?" />
        {manualField ? (
          <div className="manual-amount">
            <Field
              type="currency"
              label="Loan amount"
              hasCommas={true}
              {...fieldAdapter({
                name: "loanAmount",
                required: "Loan amount is required",
                validate: (v) =>
                  combineValidators(
                    v,
                    amountAllowedValidator(loanAmountMin, loanAmountMax),
                    wholeDollarAmountValidator
                  ),
              })}
            ></Field>
          </div>
        ) : (
          <>
            <div className="amount-row">
              <h2 className="loan-amount">{`$${(+formData.loanAmount).toLocaleString()}`}</h2>
              <a className="icon" onClick={() => setManualField(true)}>
                <Icon name="action-edit-outline" size="sm" legacy={true} />
              </a>
            </div>
            <Slider
              onChange={(value) => setValue("loanAmount", `${value}`)}
              formatLabel={(x) => `${toCurrency(x, false)}`}
              step={1000}
              minValue={loanAmountMin}
              maxValue={loanAmountMax}
              defaultValue={+formData.loanAmount || loanAmountMin}
            />
          </>
        )}
      </div>

      <ContextCard copy={getContextCardCopy()} form="quote" />

      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => nextPage()}
            disabled={isDisabled}
          >
            next
          </button>
        </Button>
      </div>
    </div>
  );
};
