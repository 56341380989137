import { UseFormTools } from "@wisr/common";
import { Field, Icon } from "@wisr/react-ui";
import dayjs from "dayjs";
import React from "react";

import { RateEstimatePages } from "../../../../../shared/analytics/analytics.consts";
import { stateCardNumberInfographic } from "../../../../../shared/application/drivers-license/card-number-infographic";
import { passportNumberValidator } from "../../../../../shared/form.utils";

import { QuoteForm } from "../../../../quote-form";
import { sendEstimateViewedEvent } from "../../../multi-page-quote-form.utils";
import identificationFieldsStyles from "./identification-fields.scss";
import {
  getLicenceCardNumberValidator,
  getLicenceNumberValidator,
} from "../../../../../forms/credit-profile/identification/identification-credit-profile-form.utils";
import { genListOption } from "../../../../../forms/credit-profile/credit-profile-form.utils";
import {
  IdentityTypes,
  australianStates,
  idOptions,
  medicareTypes,
} from "./identification-fields.options";

interface IdentificationFieldsProps {
  index: number;
  disabled: boolean;
  formData: UseFormTools<QuoteForm>["formData"];
  fieldAdapter: UseFormTools<QuoteForm>["fieldAdapter"];
  identityIndex: string;
  formDataIdentityIndex: string | string[];
}

export const IdentificationFields = ({
  index,
  disabled,
  formData,
  fieldAdapter,
  identityIndex,
  formDataIdentityIndex,
}: IdentificationFieldsProps) => {
  const [showCardNumberInfo, setShowCardNumberInfo] = React.useState(false);
  const licenceNumberValidator = getLicenceNumberValidator(
    formData.driversLicenceState
  );
  const licenceCardNumberValidator = getLicenceCardNumberValidator(
    formData.driversLicenceState
  );

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.IDENTIFICATION);
  }, []);

  return (
    <>
      <style jsx>{identificationFieldsStyles}</style>
      <Field
        id={`form_quote_input_identification_option_` + index}
        label="ID type "
        type="select"
        placeholder="Select an option"
        options={idOptions}
        disabled={disabled}
        required
        {...fieldAdapter({
          name: identityIndex as keyof UseFormTools<QuoteForm>["formData"],
          required: "Please select a form of identification",
        })}
      />
      <Field
        id={`form_quote_input_medicare_number_` + index}
        label="Card Number "
        placeholder="- - - - - - - - - -"
        type="text"
        disabled={disabled}
        maxLength={10}
        required
        {...fieldAdapter({
          name: "medicareNumber",
          required: "Please enter your medicare number",
          pattern: [/^\d{10}$/, "Please enter a valid medicare number"],
          condition: formDataIdentityIndex === IdentityTypes.MEDICARE_CARD,
        })}
      />
      <Field
        id={`form_quote_input_medicare_type_` + index}
        label="Card colour "
        type="select"
        placeholder="Select an option"
        options={medicareTypes}
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "medicareColour",
          required: "Please select the medicare colour",
          condition: formDataIdentityIndex === IdentityTypes.MEDICARE_CARD,
        })}
      />

      <Field
        id={`form_quote_input_medicare_expiry_` + index}
        label="Expiry date "
        placeholder="MM / YYYY"
        type="date"
        date="partial"
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "medicareExpiryDateGreen",
          required: "Please enter the expiry date",
          validate: (value: string) =>
            dayjs(value, "MM / YYYY", true).isValid() ||
            "Please enter a valid date.",
          condition: formDataIdentityIndex === IdentityTypes.MEDICARE_CARD,
        })}
      />

      <Field
        id={`form_quote_input_medicare_position_` + index}
        label="Reference number "
        placeholder="The position next to your name"
        type="select"
        disabled={disabled}
        options={genListOption("references")}
        required
        {...fieldAdapter({
          name: "medicareReferenceNumber",
          required: "Please select a form of identification",
          condition: formDataIdentityIndex === IdentityTypes.MEDICARE_CARD,
        })}
      />

      <Field
        id={`form_quote_input_passport_number_` + index}
        label="Passport number "
        placeholder="Enter Passport number"
        type="text"
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "passportNumber",
          required: "Please enter your passport number",
          condition: formDataIdentityIndex === IdentityTypes.PASSPORT,
          validate: passportNumberValidator,
        })}
      />

      <Field
        id={`form_quote_input_licence_state_` + index}
        layout="single"
        label="State of issue"
        type="select"
        placeholder="Select an option"
        options={australianStates}
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "driversLicenceState",
          required: "Please enter your license state",
          condition: formDataIdentityIndex === IdentityTypes.DRIVERS_LICENCE,
        })}
      />

      <Field
        id={`form_quote_input_licence_number_` + index}
        label="Licence number "
        placeholder="- - - - - - - - - -"
        type="text"
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "driverLicenceNumber",
          required: "Please enter your drivers license",
          condition: formDataIdentityIndex === IdentityTypes.DRIVERS_LICENCE,
          maxLength: [
            10,
            "Licence number cannot be more than 10 characters long",
          ],
          validate: licenceNumberValidator,
        })}
      />

      <Field
        id={`form_quote_input_licence_cardnumber_` + index}
        label="Card number"
        type="text"
        placeholder="- - - - - - - - - -"
        disabled={disabled}
        required
        {...fieldAdapter({
          name: "driverLicenceCardNumber",
          required: "Please enter licence card number",
          condition: formDataIdentityIndex === IdentityTypes.DRIVERS_LICENCE,
          minLength: [
            6,
            "Licence card number must be at least 6 characters long",
          ],
          maxLength: [
            10,
            "Licence card number cannot be more than 10 characters long",
          ],
          validate: licenceCardNumberValidator,
        })}
      />

      {formDataIdentityIndex === IdentityTypes.DRIVERS_LICENCE && (
        <div className="card-number-description">
          <div
            className={`info-button-wrap ${showCardNumberInfo ? "active" : ""}`}
          >
            <Icon name="status-info-outline" size="sm" legacy={true} />
            <p
              className="card-number-label"
              onClick={() => setShowCardNumberInfo(true)}
            >
              Where do I find my card number?
            </p>
          </div>

          {stateCardNumberInfographic({ showCardNumberInfo, formData })}
        </div>
      )}
    </>
  );
};
