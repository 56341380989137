import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { RateEstimatePages } from "../../../../shared/analytics/analytics.consts";
import { QuoteForm, TimeSpentOption } from "../../../quote-form";
import { timeSpentOptions } from "../../../quote-form.constants";
import { FormPageHeading } from "../../form-page-heading/form-page-heading";
import style from "../../multi-page-quote-form.scss";
import {
  sendEstimateViewedEvent,
  timeSpentMapper,
} from "../../multi-page-quote-form.utils";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<QuoteForm>["setValue"];
}

export const PreviousMovedIn: React.FC<PropTypes> = ({ setValue }) => {
  const [fieldValue, setFieldValue] = React.useState<TimeSpentOption>();

  React.useEffect(() => {
    if (fieldValue !== undefined) {
      const selectedValue = timeSpentMapper(fieldValue);
      setValue("monthsAtPreviousAddress", `${selectedValue.months}`);
      setValue("yearsAtPreviousAddress", `${selectedValue.years}`);
      setFieldValue(undefined);
      navigate("/quote/review");
    }
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendEstimateViewedEvent(RateEstimatePages.PREVIOUS_MOVED_IN);
  }, []);

  return (
    <div className="multi-page">
      <style jsx>{style}</style>
      <FormPageHeading title="How long did you live at this address?" />

      <Field
        type="radio-list"
        label=""
        options={timeSpentOptions}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value as TimeSpentOption);
        }}
      />
    </div>
  );
};
